#password-field-underline-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.password-field-underline-main {
    border-bottom: 1px solid #D8D5D5;
}

.password-field-underline-main-selected {
    border-bottom: 1px solid var(--logo-green);
}

#password-field-underline-text-label-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40%;
}

#password-field-underline-text-label {
    display: flex;
    width: 70%;
    font-size: 1.3em;
    margin-left: 3.5%;
    color: #7D7D7D;
    user-select: none;
}

#password-field-underline-text-edit {
    display: flex;
    margin-left: 30%;
    font-size: 1.2em;
    color: #7D7D7D;
    cursor: pointer;
    justify-self: flex-start;
    user-select: none;
}

#password-field-underline-text-edit:hover {
    animation: blueColorAnimation2 0.1s forwards linear;
    text-decoration: underline;
    user-select: none;
}

#password-field-underline-text-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

#password-field-underline-text-input {
    display: flex;
    width: 80%;
    height: 100%;
    font-size: 1.4em;
    padding: 0;
    border: none;
    outline: none;
    caret-color: #7D7D7D;
    color: #7D7D7D;
}

#password-field-underline-show-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12%;
    height: 100%;
    user-select: none;
    cursor: pointer;
}


@media screen and (max-width: 650px) {
    #password-field-underline-text-label {
        display: flex;
        width: 70%;
        font-size: 1em;
        margin-left: 3.5%;
        color: #7D7D7D;
        user-select: none;
    }

    #password-field-underline-text-input {
        display: flex;
        width: 93%;
        height: 100%;
        font-size: 1.1em;
        /* margin-left: 3.5%; */
        padding: 0;
        border: none;
        outline: none;
        caret-color: #7D7D7D;
        color: #7D7D7D;
    }
}
