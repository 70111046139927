#header-menu-main {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
    height: 100%;
    z-index: 9999;
}

.header-menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 9999;
    user-select: none;
    text-decoration: none;
}

#header-menu-btn-txt {
    display: flex;
    font-size: 1.5em;
    color: var(--dark-gray);
    z-index: 9999;
}

#header-menu-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow: visible;
    margin: 0;
    padding: 0;
    list-style: none;
    height: auto;
    width: 100px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-bottom-right-radius: var(--main-border-radius);
    border-bottom-left-radius: var(--main-border-radius);
    z-index: 9999;

}


#user-cart-menu-entry-exit {
    z-index: 9999;
    justify-content: flex-start;
}

@keyframes grow {
    0% {
      height: 0px;
    }
    100% {
      height: auto;
    }
}

@keyframes shrink {
    0% {
        height: 200px;
    }
    100% {
      height: 0px;
    }
}


