#basic-dropdown-without-label-main {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
}

.basic-dropdown-without-label-btn {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    height: 100%;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: var(--main-border-radius);
    background-color: #FFFFFF;
    border: 1px solid #d8d5d5;
}

.basic-dropdown-without-label-value-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.basic-dropdown-without-label-order-selector-value-container {
    display: flex;
    align-items: center;
    width: 85%;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-sizing: border-box;
}

#basic-dropdown-without-label-order-selector-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

#basic-dropdown-without-label-order-selector-selector-img {
    display: flex;
    width: 70%;
    user-select: none;
}

#basic-dropdown-without-label-value-txt {
    display: flex;
    width: 100%;
    height: auto;
    padding: 0 3%;
    font-size: 1vw;
    color: #7D7D7D;
}

#basic-dropdown-without-label-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow: visible;
    margin: 0;
    padding: 0;
    list-style: none;
    height: fit-content;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-bottom-right-radius: var(--main-border-radius);
    border-bottom-left-radius: var(--main-border-radius);

}


@keyframes grow {
    0% {
      height: 0px;
    }
    100% {
      height: auto;
    }
}

@keyframes shrink {
    0% {
        height: 200px;
    }
    100% {
      height: 0px;
    }
}


