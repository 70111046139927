#checkbox-main {
    display: flex;
    width: 100%;
    height: 100%;
    /* background-color: aquamarine; */
}

#checkbox-selection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 15%;
    height: 100%;
    /* background-color: yellow; */
}

#checkbox-selection-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: var(--main-border-radius);
    border: 1px solid #d8d5d5;
    cursor: pointer;
}

#checkbox-selection-box-img {
    display: flex;
    width: 95%;
}

#checkbox-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 85%;
    height: 100%;
    /* background-color: turquoise; */
}

#checkbox-label-txt {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 1vw;
    color: var(--dark-gray);
    user-select: none;
    background-color: transparent;
}
