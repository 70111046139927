#user-menu-navigation-element-main {
    display: flex;
    flex-direction: row;
    width: max-content;
    height: 30%;
    /* background-color: yellow; */
}

#user-menu-navigation-element-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    height: 100%;
    text-decoration: none;
    /* background-color: turquoise; */
}

#user-menu-navigation-element-link-txt {
    font-size: 1vw;
    margin-left: 0.2vw;
    color: #a8a6a6;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    user-select: none;
    /* background-color: aliceblue; */
}

#user-menu-navigation-element-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.2vw;
    height: 100%;
}

#user-menu-navigation-element-img {
    display: flex;
    width: 100%;
    user-select: none;
    /* background-color: antiquewhite; */
}