#cart-authentication-register-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

#cart-authentication-register-field-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70%;
}

.cart-authentication-register-field-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}

#cart-authentication-register-warning {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 8%;
}

#cart-authentication-register-warning-txt {
    display: flex;
    user-select: none;
    font-size: 1.2em;
    color: var(--logo-orange);
    animation: fade 0.5s
}

#cart-authentication-register-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 22%;
}

#cart-authentication-register-register-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 46px;
    cursor: pointer;
    border-radius: var(--main-border-radius);
    background-color: var(--logo-green);
}

#cart-authentication-register-register-btn-txt {
    display: flex;
    font-size: 1.2em;
    font-weight: 500;
    user-select: none;
    color: #FFFFFF;
}


#cart-authentication-register-login-btn {
    display: flex;
    width: auto;
    height: auto;
    font-size: 1.4em;
    margin-top: 1%;
    color: var(--dark-gray);
    cursor: pointer;
    user-select: none;
}


#cart-authentication-register-login-btn:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}


.cart-authentication-register-field {
    display: flex;
    width: 80%;
    height: 80px;
    margin-bottom: 30px;
}

@media screen and (max-width: 650px) {

    #cart-authentication-register-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        height: auto;
    }

    #cart-authentication-register-field-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
    }

    .cart-authentication-register-field-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: auto
    }

    .cart-authentication-register-field {
        display: flex;
        width: 100%;
        height: 65px;
        margin-bottom: 5%;
    }
    

    #cart-authentication-register-btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: auto;
    }

    #cart-authentication-register-register-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 46px;
        margin-top: 5%;
        cursor: pointer;
        border-radius: var(--main-border-radius);
        background-color: var(--logo-green);
    }
    
    
    #cart-authentication-register-login-btn {
        display: flex;
        width: auto;
        height: auto;
        font-size: 1.5em;
        margin-top: 4%;
        margin-bottom: 10%;
        color: var(--dark-gray);
        cursor: pointer;
        user-select: none;
    }
}