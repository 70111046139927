#product-edit-image-list-image-main {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 90%;
    /* height: 45%; */
    height: 22vw;
    background-color: #FFFFFF;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    border-radius: var(--main-border-radius);
    box-sizing: border-box;
    border: var(--main-border);
}

#product-edit-image-list-image-main:first-child {
    margin-top: 10%;
}

#product-edit-image-list-image-main:last-child {
    margin-bottom: 10%;
}


#product-edit-image-list-image-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 10%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

#product-edit-image-list-image-btns-delete {
    display: flex;
    width: 10%;
    cursor: pointer;
    transition-duration: 0.1s;
    transform: scale(1);
    user-select: none;
    margin-right: 5%;
}

#product-edit-image-list-image-btns-delete:hover {
    display: flex;
    transition-duration: 0.1s;
    transform: scale(1.3);
}

#product-edit-image-list-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
    overflow: hidden;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

#product-edit-image-list-image {
    display: flex;
    width: auto;
    height: 100%;
    user-select: none;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    object-fit: cover;
    object-position: center;
}

