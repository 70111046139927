#basic-dropdown-option-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    cursor: pointer;
}

#basic-dropdown-option-main:hover {
    background-color: var(--main-blue);
}

#basic-dropdown-option-main:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

#basic-dropdown-option-value {
    display: flex;
    font-size: 1vw;
    user-select: none;
    color: var(--dark-gray);
}