#login-container {
    display: flex;
    flex-direction: column;
    width: 672px;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.2s linear;
}

#login-logo-container {
    display: flex;
    height: 25%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#login-logo-link {
    display: flex;
    height: fit-content;
    width: 25%;
    justify-content: center;
    align-items: center;
}

#login-logo {
    display: flex;
    width:  100%;
}

#login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 75%;
    border-radius: inherit;
}

.login-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 80px;
    margin-bottom: 25px;
}

.login-input-container:first-child {
    display: flex;
    width: 75%;
    height: 70px;
    margin-top: 5%;
    margin-bottom: 12%;
}

#login-text {
    display: flex;
    font-size: 2.5em;
    color: var(--light-gray);
    user-select: none;
}

#login-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
}

#login-warning-txt {
    display: flex;
    user-select: none;
    font-size: 1.2em;
    color: var(--logo-orange);
    animation: fade 0.5s
}

#login-input-container-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25%;
}

#login-input-container-btn-login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 46px;
    border-radius: var(--main-border-radius);
    cursor: pointer;
    background-color: var(--logo-green);
}

#login-input-container-btn-login-txt {
    display: flex;
    font-size: 1.2em;
    font-weight: 500;
    color: #ffffff;
    user-select: none;
}

#login-input-container-btn-forgot-password {
    display: flex;
    width: auto;
    height: auto;
    color: var(--light-gray);
    font-size: 1.2em;
    margin-top: 1.5%;
    cursor: pointer;
    user-select: none;
}


#login-input-container-btn-register {
    display: flex;
    width: auto;
    height: auto;
    font-size: 1.3em;
    margin-top: 2%;
    color: var(--dark-gray);
    cursor: pointer;
    user-select: none;
}

#login-input-container-btn-forgot-password:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

#login-input-container-btn-register:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

@media screen and (max-width: 650px) {
    .login-input-container {
        display: flex;
        width: 95%;
        height: 65px;
        margin-bottom: 25px;
    }
    
    .login-input-container:first-child {
        display: flex;
        width: 75%;
        height: 70px;
        margin-top: 2%;
        margin-bottom: 15%;
    }

    #login-warning-message {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
    }

    #login-input-container-btn-login {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 46px;
        margin-top: 5%;
        cursor: pointer;
        border-radius: var(--main-border-radius);
        background-color: var(--logo-green);
    }

    #login-input-container-btn-forgot-password {
        display: flex;
        width: auto;
        height: auto;
        color: var(--light-gray);
        font-size: 1.2em;
        margin-top: 3%;
        cursor: pointer;
        user-select: none;
    }
    
}