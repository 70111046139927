#cart-address-create-address-main {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    height: 613px;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
}

#cart-address-create-address-label {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 10%;
}

#cart-address-create-address-label-txt {
    display: flex;
    color: var(--light-gray);
    font-size: 1.5em;
    user-select: none;
}

#cart-address-create-address-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 55%;
}

#card-address-create-address-warning-txt {
    display: flex;
    text-align: center;
    user-select: none;
    font-size: 1.2em;
    color: var(--logo-orange);
    animation: fade 0.5s
}

#cart-address-create-address-fields {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90%;
}

.cart-address-create-address-fields-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 100%;
}

.cart-address-create-address-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 13.334%;
    margin-top: 8%;
}

.cart-address-create-address-sub-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
}

#cart-address-create-address-btns-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    width: 80%;
    height: 13.334%;
    margin-top: 8%;
    /* background-color: aqua; */
}


#cart-address-create-address-btns-continue {
    display: flex;
    color: var(--light-gray);
    font-size: 1.6vw;
    cursor: pointer;
    user-select: none;
}

#cart-address-create-address-btns-continue:hover {
    animation: var(--set-blue-color)
}













@media screen and (max-width: 650px) {
    #cart-address-create-address-main {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        height: 800px;
        margin: 0;
        padding: 0;
        flex-shrink: 0;
    }

    #cart-address-create-address-label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95%;
        height: 10%;
    }

    #cart-address-create-address-label-txt {
        display: flex;
        color: var(--light-gray);
        font-size: 1.5em;
        user-select: none;
        text-align: center;
    }

    #cart-address-create-address-fields {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 90%;
    }

    .cart-address-create-address-fields-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 50%;
    }

    .cart-address-create-address-fields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 90%;
        height: 18%;
        margin-top: 6%;
    }


    #cart-address-create-address-btns-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 95%;
        height: 20%;
        margin-top: 2%;
    }

    #cart-address-create-address-btns-continue {
        display: flex;
        color: var(--light-gray);
        font-size: 1.9em;
        cursor: pointer;
        user-select: none;
    }
}