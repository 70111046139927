#order-product-card-main:first-child {
    margin-top: 3%;
}

#order-product-card-main {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 23.3%;
    align-items: center;
    margin-bottom: 2%;
    border-radius: var(--main-border-radius);
    cursor: pointer;
    flex-shrink: 0;
    box-sizing: border-box;
    border: var(--main-border);
}

#order-product-card-main:last-child {
    margin-bottom: 4%;
}

#order-product-card-main-link {
    display: flex;
    width: 100%;
    height: 100%;
    text-decoration: none;
    border-radius: inherit;
}

#order-product-card-main-img-container {
    display: flex;
    width: 26%;
    height: 100%;
    background-color: aquamarine;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

#order-product-card-main-img {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    user-select: none;
    object-fit: cover;
    object-position: center;
}

#order-product-card-main-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 74%;
    height: 100%;
    /* background-color: rgb(185, 255, 127); */
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
}

.order-product-card-main-data {
    display: flex;
    align-items: center;
    width: 100%;
    height: 17%;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

.order-product-card-main-data-label {
    display: flex;
    width: fit-content;
    height: fit-content;
    text-align: left;
    margin-right: 3%;
    margin-left: 4%;
    font-size: 0.9vw;
    color: #6e6e6e;
    user-select: none;
}

.order-product-card-main-data-value {

}