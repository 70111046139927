#admin-product-variation-content {
    display: flex;
    width: 100%;
    height: 92%;
}

#admin-product-variation-content-primary {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 43%;
    height: 100%;
    border-right: var(--main-border);
}

.admin-product-variation-field {
    display: flex;
    margin-top: 3%;
    width: 90%;
    height: 14.132%;
}

#admin-product-variation-checkbox-field1 {
    display: flex;
    margin-top: 2%;
    width: 90%;
    height: 10%;
}

#admin-product-variation-checkbox-field2 {
    display: flex;
    width: 90%;
    height: 10%;
}

#admin-product-variation-delete {
    display: flex;
    align-items: flex-start;
    width: 90%;
    height: 6%;
    margin-top: 3%;
}

#admin-product-variation-delete-btn {
    font-size: 1.3vw;
    color: var(--light-gray);
    cursor: pointer;
    user-select: none;
    font-weight: 500;
}

#admin-product-variation-delete-btn:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    animation: orangeColorAnimation 0.1s linear forwards;
}


#admin-product-variation-content-secondary {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    height: 100%;
}

#admin-product-variation-content-terciary {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 21%;
    height: 100%;
}