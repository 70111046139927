#admin-product-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 73vh;
    background-color: #FFFFFF;
}

#admin-product-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90%;
}

#admin-product-primary {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
    height: 100%;
    box-sizing: border-box;
    border-right: var(--main-border);
    z-index: 0;
}

.admin-product-primary-field {
    display: flex;
    margin-top: 3%;
    width: 80%;
    height: 13%;
    overflow: visible;
}

#admin-product-primary-checkbox-field1 {
    display: flex;
    margin-top: 3%;
    width: 80%;
    height: 10%;
}

#admin-product-primary-checkbox-field2 {
    display: flex;
    width: 80%;
    height: 10%;
}

#admin-product-primary-description {
    display: flex;
    margin-top: 3%;
    width: 80%;
    height: 25%;
    overflow: visible;
}

#admin-product-variation {
    display: flex;
    flex-direction: column;
    width: 67%;
    height: 100%;
}

#admin-product-variation-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 92%;
}

#admin-product-variation-placeholder-txt {
    display: flex;
    font-size: 2.5vw;
    user-select: none;
    color: var(--light-gray);
}