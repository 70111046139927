#product-new-size-list-size-element-textinput-main {
    display: flex;
    width: 80%;
    height: 55%;
}

#product-new-size-list-size-element-textinput-field {
    width: 100%;
    height: 100%;
    padding: 0 3%;
    border: none;
    outline: none;
    border-radius: inherit;

    font-size: 1vw;
    caret-color: var(--dark-gray);
    color: var(--dark-gray);
    font-weight: 400;
    font-size: 1.2vw;
    background-color: transparent;
}