#cart-authentication-confirm-email-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
}

#cart-authentication-confirm-email-input {
    display: flex;
    width: 60%;
    height: 90px;
    /* background-color: rgb(218, 197, 12); */
}

#cart-authentication-confirm-email-adversiment {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 20%;
    flex-shrink: 0;
}

#cart-authentication-confirm-email-adversiment-txt {
    display: flex;
    user-select: none;
    font-size: 1.2em;
    color: var(--logo-orange);
    animation: fade 0.5s
}

#cart-authentication-confirm-email-resend-timer {
    display: flex;
    width: 80%;
    height: 30px;
}

#cart-authentication-confirm-email-confirm-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 370px;
    height: 45px;
    border-radius: var(--main-border-radius);
    background-color: var(--logo-green);
    cursor: pointer;
}

#cart-authentication-confirm-email-confirm-btn-txt {
    display: flex;
    font-size: 1.2em;
    font-weight: 500;
    user-select: none;
    color: #FFFFFF;
}

#cart-authentication-confirm-email-resend-btn {
    display: flex;
    width: auto;
    height: auto;
    color: var(--light-gray);
    font-size: 1.2em;
    margin-top: 2%;
    cursor: pointer;
    user-select: none;
}

#cart-authentication-confirm-email-resend-btn:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

@media screen and (max-width: 650px) {

    #cart-authentication-confirm-email-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        height: auto;
    }

    #cart-authentication-confirm-email-input {
        display: flex;
        width: 70%;
        height: 90px;
        /* background-color: rgb(218, 197, 12); */
    }

    #cart-authentication-confirm-email-adversiment {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 50px;
    }

    #cart-authentication-confirm-email-confirm-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        height: 45px;
        border-radius: var(--main-border-radius);
        background-color: var(--logo-green);
        cursor: pointer;
    }
}