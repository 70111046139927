#admin-product-variation-image-list-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    width: 100%;
    height: 100%;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    /* background-color: blue; */
}

#admin-product-variation-image-list-main::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

#admin-product-image-list-new-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 90%;
    height: 50px;
    margin-bottom: 6%;
    margin-top: 10px;
    border-radius: var(--main-border-radius);
    border: var(--main-border);
    background-color: var(--light-gray);
    cursor: pointer;
}
