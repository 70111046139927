#basic-dropdown-option-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    cursor: pointer;
}

#basic-dropdown-option-main:hover {
    background-color: var(--main-blue);
}

#basic-dropdown-option-main:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

#basic-dropdown-option-value {
    display: flex;
    font-size: 1.4em;
    user-select: none;
    color: var(--dark-gray);
}

/* @media screen and (max-width: 650px) {
    #basic-dropdown-option-value {
        display: flex;
        font-size: 70px;
        user-select: none;
        color: var(--dark-gray);
        background-color: violet;
    }
    
} */
