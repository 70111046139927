#product-details-size-element-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 1.5%;
    width: 2em;
    height: 2em;
    border-radius: 10px;
    /* border: 1px solid var(--light-gray);  */
    cursor: pointer;
}

.product-details-size-element-main-selected {
    border: 2px solid var(--dark-gray); 
}

.product-details-size-element-main-unselected {
    border: 2px solid var(--light-gray); 
}

.product-details-size-element-main-disabled {
    border: 2px solid var(--light-gray); 
}


#product-details-size-element-txt {
    display: flex;
    /* color: var(--dark-gray); */
    font-size: 1.3em;
    user-select: none;
}
.product-details-size-element-txt-enabled {
    color: var(--dark-gray);
}

.product-details-size-element-txt-disabled {
    color: var(--light-gray);
}