#user-profile-address-card-main {
    display: flex;
    flex-direction: column;
    width: 310px;
    height: 350px;
    flex-shrink: 0;
    margin-inline: 1%;
    margin-top: 1%;
    margin-bottom: 0.5%;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #d8d5d5;    
}

#user-profile-address-card-main:last-child {
    margin-bottom: 5%;
}

#user-profile-address-card-header {
    display: flex;
    width: 100%;
    height: 15%;
    box-sizing: border-box;
    border-bottom: 1px solid #d8d5d5;    
}

#user-profile-address-card-header-name {
    display: flex;
    align-items: center;
    width: 85%;
    height: 100%;
    /* background-color: aliceblue; */
}

#user-profile-address-card-header-name-txt {
    display: flex;
    margin-left: 5%;
    font-size: 1em;
}

#user-profile-address-card-header-default-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100%;
    user-select: none;
    /* background-color: rgb(142, 187, 226); */
}

#user-profile-address-card-header-default-img {
    display: flex;
    width: 80%;
}

#user-profile-address-card-body {
    display: flex;
    justify-content: center;
    
    flex-direction: column;
    width: 100%;
    height: 70%;
}

.user-profile-address-card-body-data-subcontainer-spacer {
    margin-left: 4%;
}

.user-profile-addres-card-body-data-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 18.8%;
}

.user-profile-address-card-body-data-subcontainer {
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
}

.user-profile-addres-card-body-data-container2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 18.8%;
}


.user-profile-address-card-body-data-title-txt {
    display: flex;
    width: auto;
    height: auto;
    margin-left: 10px;
    font-size: 1em;
    color: var(--light-gray);
    user-select: none;
}

.user-profile-address-card-body-data-txt {
    display: flex;
    word-break: normal;
    width: auto;
    height: auto;
    margin-left: 10px;
    font-size: 1em;
}

#user-profile-address-card-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: var(--main-border);
    box-sizing: border-box;
    width: 100%;
    height: 15%;
    /* background-color: aqua; */
}

.user-profile-address-card-footer-btns {
    cursor: pointer;
    color: #a1a0a0;
    user-select: none;
    margin-left: 5%;
}

.user-profile-address-card-footer-btns:hover {
    animation: var(--set-blue-color2);
    text-decoration: underline;
}

@media screen and (max-width: 500px) {
    #user-profile-address-card-main {
        display: flex;
        flex-direction: column;
        width: 310px;
        height: 350px;
        flex-shrink: 0;
        margin-top: 2%;
        margin-bottom: 1%;
        border-radius: 10px;
        box-sizing: border-box;
        border: 1px solid #d8d5d5;    
        /* background-color: rgb(255, 127, 127); */
    }
}