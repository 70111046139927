#user-profile-card-card-main {
    display: flex;
    width: 95%;
    height: 200px;
    flex-shrink: 0;
    flex-direction: row;
    box-sizing: border-box;
    border-radius: var(--main-border-radius);
    border: 1px solid #d8d5d5;
    margin-bottom: 2%;
}

#user-profile-card-img-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 170px;
    min-width: 170px;
    height: 100%;
}

#user-profile-card-img {
    display: flex;
    margin-left: 4%;
    height: 93%;
    width: auto;
    user-select: none;
    border-radius: var(--main-border-radius);
    object-fit: cover;
    object-position: center;
}

#user-profile-card-info {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
}

#user-profile-card-info-name {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30%;
}

#user-profile-card-info-name-txt {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 1.5em;
    color: var(--dark-gray);
}

#user-profile-card-info-general {
    display: flex;
    width: 100%;
    height: 70%;
}

#user-profile-card-info-general1 {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
}

#user-profile-card-info-general2 {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
}

#user-profile-card-info-general-field-size {
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
}

#user-profile-card-info-general3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 20%;
    height: 100%;
}

#user-profile-card-info-general3-delete-btn {
    display: flex;
    width: 30%;
}

.user-profile-card-info-general-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50%;
}

.user-profile-card-info-general-field-label {
    display: flex;
    color: var(--light-gray);
    font-size: 1.1em;
    user-select: none;
}

.user-profile-card-info-general-field-value {
    display: flex;
    font-size: 1.4em;   
    color: var(--dark-gray);
    margin-top: 0.5%;
}

#user-profile-card-info-general-field-size-change-remove {
    display: flex;
    font-size: 1.4em;
    color: var(--dark-gray);
    margin-right: 10%;
    cursor: pointer;
    user-select: none;
}

#user-profile-card-info-general-field-size-change-add {
    display: flex;
    font-size: 1.4em;
    color: var(--dark-gray);
    margin-left: 10%;
    cursor: pointer;
    user-select: none;
}

#user-profile-card-info-btn {
    display: flex;
    width: 100%;
    height: 15%;
}

#user-profile-card-info-btn-delete {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 1.2em;
    margin-right: 4%;
    color: var(--light-gray);
    user-select: none;
    cursor: pointer;
}

#user-profile-card-info-btn-delete:hover {
    color: var(--logo-blue);
    text-decoration: underline;
    text-decoration-thickness: 1px;
    animation: var(--set-blue-color);
}


@media screen and (max-width: 1100px) {
    #user-profile-card-card-main {
        display: flex;
        width: 95%;
        height: 160px;
        flex-shrink: 0;
        flex-direction: row;
        box-sizing: border-box;
        border-radius: var(--main-border-radius);
        border: 1px solid #d8d5d5;
        margin-bottom: 2%;
    }

    #user-profile-card-img-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 130px;
        min-width: 130px;
        height: 100%;
    }

    .user-profile-card-info-general-field-label {
        display: flex;
        color: var(--light-gray);
        font-size: 1em;
        user-select: none;
    }
    
    .user-profile-card-info-general-field-value {
        display: flex;
        font-size: 1em;
        color: var(--dark-gray);
        margin-top: 0.5%;
    }

    #user-profile-card-info-general-field-size-change-remove {
        display: flex;
        font-size: 1em;
        color: var(--dark-gray);
        margin-right: 10%;
        cursor: pointer;
        user-select: none;
    }
    
    #user-profile-card-info-general-field-size-change-add {
        display: flex;
        font-size: 1em;
        color: var(--dark-gray);
        margin-left: 10%;
        cursor: pointer;
        user-select: none;
    }
    
}

@media screen and (max-width: 1100px) {
    #user-profile-card-info-name-txt {
        display: flex;
        width: fit-content;
        height: fit-content;
        font-size: 1em;
        color: var(--dark-gray);
    }
}