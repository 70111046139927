#admin-order-card-main {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 70px;
    align-items: center;
    margin-bottom: 2%;
    border-radius: var(--main-border-radius);
    cursor: pointer;
    flex-shrink: 0;
    border: var(--main-border);
    background-color: #FFFFFF;
}

#admin-order-card-main:first-child {
    margin-top: 3%;
}

#admin-order-card-main:last-child {
    margin-bottom: 4%;
}

#admin-order-card-main-order-id-container {
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
}

#admin-order-card-main-order-id {
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 1.1vw;
    color: var(--dark-gray);
    margin-left: 11.5%;
}


#admin-order-card-main-order-date-container {
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
}

#admin-order-card-main-order-date {
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 1.1vw;
    margin-left: 25%;
    color: var(--dark-gray);
}