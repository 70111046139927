#header-admin-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 168px;
    height: 40%;
    margin-right: 5%;
    border-radius: var(--main-border-radius);
    border: var(--main-border);
    cursor: pointer;
}


#header-admin-menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    text-decoration: none;
}

#header-admin-menu-btn-txt-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

#header-admin-menu-btn-txt {
    display: flex;
    font-size: 1.3em;
    color: var(--dark-gray);
    text-decoration: none;
    user-select: none;
}

#header-admin-menu-btn-txt:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

#header-admin-menu-icon {
    display: none;
    width: 60%;
}

@media screen and (max-width: 1121px) {
    #header-admin-main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40%;
        width: 57px;
        margin-right: 5%;
        border-radius: var(--main-border-radius);
        border: var(--main-border);
        cursor: pointer;
        flex-shrink: 0;
    }

    #header-admin-menu-btn-txt-container {
        display: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    #header-admin-menu-icon {
        display: flex;
        width: 65%;
    }
}

@media screen and (max-width: 805px) {
    #header-admin-main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 9vw;
        width: 9vw;
        margin-right: 5%;
        border-radius: var(--main-border-radius);
        border: var(--main-border);
        cursor: pointer;
        flex-shrink: 0;
    }
}