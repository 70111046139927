#product-page-body-details-image-slider {
    display: flex;
    width: 50%;
    height: auto;
}

.product-page-body-details-image-slider {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    height: auto;
}

#product-page-body-details-image-slider-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 25%;
    height: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    /* background-color: rgb(48, 122, 122); */
}

#product-page-body-details-image-slider-list::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.product-page-body-details-image-slider-list-element:first-child {
    display: flex;
    margin-top: 0;
    justify-content: center;
    width: 100px;
    height: 134px;
    border-radius: var(--main-border-radius);
}

.product-page-body-details-image-slider-list-element {
    display: flex;
    margin-top: 3%;
    margin-right: 5%;
    justify-content: center;
    width: 100px;
    height: 134px;
    cursor: pointer;
    border-radius: var(--main-border-radius);
}

#product-page-body-details-image-slider-list-element-img {
    display: flex;
    width: 100%;
    border-radius: var(--main-border-radius);
    object-fit: cover;
    object-position: center;
}

.product-page-body-details-image-slider-focused {
    width: 65%;
    border-radius: var(--main-border-radius);
}

.product-page-body-details-image-slider-focused-img-container {
    border-radius: var(--main-border-radius);
}

.product-page-body-details-image-slider-focused-img {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: var(--main-border-radius);
}








#product-page-body-details-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
}    

#product-page-body-details-info {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    flex-shrink: 0;
}    

#product-page-body-details-info-name {
    display: flex;
    align-items: center;
    width: 100%;
    height: 13%;
}    

#product-page-body-details-info-name-txt {
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 1.5em;
    color: #000000;
}    

#product2-details-code {
    display: flex;
    width: 100%;
    height: 5%;
    /* background-color: bisque; */
}    

#product2-details-code-txt {
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 0.9vw;
    color: var(--light-gray);
}    

#product2-details-favorite-container {
    display: flex;
    width: 100%;
    height: 8%;
}    

#product2-details-price {
    display: flex;
    align-items: center;
    width: 100%;
    height: 15%;
}    

#product2-details-price-txt {
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 2.7em;
    color: #000000;
}    

#product2-details-price-promotion {
    display: flex;
    align-items: center;
    width: 100%;
    height: 15%;
}    

#product2-details-price-promotion-txt {
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 1.4em;
    margin-top: 3%;
    color: var(--light-gray);
    text-decoration: line-through;
}    

#product2-details-price-promotion-new-txt {
    width: fit-content;
    height: fit-content;
    margin-right: 5%;
    color: var(--logo-orange);
    text-align: left;
    font-size: 2.7em;
}    


#product2-details-option-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 17%;
}    

#product-2-details-option-selector {
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 100%;
}    

#product-2-details-option-stock {
    display: flex;
    width: 35%;
    height: 100%;
    /* background-color: tomato; */
}    

#product2-details-size-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 17%;
    /* background-color: aqua; */
}    

#product2-details-freight-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 15%;
    margin-top: 2%;
}    

#product2-details-btns-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 12%;
    margin-top: 2%;
}    

#product2-details-btn-buy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 85%;
    border-radius: var(--main-border-radius);
    background-color: var(--logo-green);
    margin-right: 4%;
    cursor: pointer;
}    

#product2-details-btn-buy-txt {
    display: flex;
    font-size: 1.5em;
    font-weight: 500;
    user-select: none;
    color: #FFFFFF;
}    

#product2-details-btn-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62.4px;
    height: 85%;
    border-radius: var(--main-border-radius);
    background-color: var(--logo-green);
    cursor: pointer;
}    

#product2-details-btn-add-img {
    display: flex;
    width: 50%;
    user-select: none;
}    

.product2-details-label-txt {
    display: flex;
    align-items: center;
    width: auto;
    height: 40%;
    font-size: 1em;
    user-select: none;
    color: var(--light-gray);
}    

@media screen and (max-width: 1250px) {
    #product-page-body-details-main {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: auto;
        background-color: #FFFFFF;
    }    
   
    #product-page-body-details-image-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 500px;
    }

    .product-page-body-details-image-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        width: 70%;
    }

    #product-page-body-details-image-slider-list {
        display: none;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 18%;
    }


    .product-page-body-details-image-slider-list-element:first-child {
        display: none;
        justify-content: center;
        width: 6vw;
        height: 7vw;
        border-radius: var(--main-border-radius);
        margin: 0;
    }
    
    .product-page-body-details-image-slider-list-element {
        display: none;
        justify-content: center;
        width: 6vw;
        height: 7vw;
        border-radius: var(--main-border-radius);
        margin: 0;
    }

    #product-page-body-details-info {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 500px;
        flex-shrink: 0;
        /* background-color: rgb(202, 95, 23); */
    }    

    #product-page-body-details-info-name {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 13%;
        margin-top: 5%;
    }    
    
    #product2-details-code {
        display: none;
        width: 100%;
        height: 5%;
        /* background-color: rgb(66, 38, 5); */
    }    
    
    #product2-details-code-txt {
        width: fit-content;
        height: fit-content;
        text-align: left;
        font-size: 0.7vw;
        color: var(--light-gray);
    }    
    
    #product2-details-favorite-container {
        display: flex;
        width: 100%;
        height: 8%;
    }    
    
    #product2-details-price {
        display: flex;
        align-items: center;
        width: 100%;
        height: 15%;
    }    
    
    #product2-details-price-promotion {
        display: flex;
        align-items: center;
        width: 100%;
        height: 15%;
    }    
    
    #product2-details-option-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 17%;
        /* background-color: blue; */
    }    
    
    #product-2-details-option-selector {
        display: flex;
        flex-direction: column;
        width: 65%;
        height: 100%;
    }    
    
    #product-2-details-option-stock {
        display: flex;
        width: 35%;
        height: 100%;
        /* background-color: tomato; */
    }    
    
    #product2-details-size-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 17%;
        /* background-color: aqua; */
    }    
    
    #product2-details-freight-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 15%;
        margin-top: 2%;
    }    
    
    #product2-details-btns-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 12%;
        margin-top: 2%;
    }    
}

@media screen and (max-width: 896px) {

    #product-page-body-details-main {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: auto;
    }    
   
    #product-page-body-details-image-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 500px;
    }

    .product-page-body-details-image-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        width: 100%;
        /* background-color: azure; */
    }

    .product-page-body-details-image-slider-focused {
        width: 80%;
        height: auto;
        border-radius: var(--main-border-radius);
        /* background-color: yellow; */
    }

    #product-page-body-details-image-slider-list {
        display: none;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 0%;
    }

  

    #product-page-body-details-info {
        display: flex;
        flex-direction: column;
        width: 90%;
        height: 500px;
        flex-shrink: 0;
        /* background-color: rgb(202, 95, 23); */
    }    

    #product2-details-code {
        display: none;
        width: 100%;
        height: 5%;
        /* background-color: bisque; */
    }  

}

@media screen and (max-width: 500px) {
    #product-page-body-details-image-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    
    #product-page-body-details-image-slider-list {
        display: none;
        justify-content: center;
        width: 100%;
        height: 0%;
    }
    

    .product-page-body-details-image-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        width: 100%;
    }

    .product-page-body-details-image-slider-focused {
        width: 100%;
        border-radius: var(--main-border-radius);
    }

    #product-page-body-details-image-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 600px;
    }

    /* #product-page-body-details-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        background-color: rgb(165, 114, 18);
    }     */
    
    #product2-details-btns-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 12%;
        margin-top: 10%;
        margin-bottom: 5%;

    }    

}