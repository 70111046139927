#user-profile-personal-data-edit-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 683px;
}

#user-profile-personal-data-edit-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
    background-color: aqua;
}

#user-profile-personal-data-edit-label-txt {
    display: flex;
    color: var(--light-gray);
    font-size: 2em;
    user-select: none;
}

#user-profile-personal-data-edit-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
    background-color: yellow;
}

#user-profile-personal-data-edit-text {
    display: flex;
    width:  40%;
    height: 77px;
}

.user-profile-personal-data-edit-text2 {
    display: flex;
    width: 40%;
    height: 77px;
    margin-top: 3%;
}

#user-profile-personal-data-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15%;
    background-color: tomato;
}

#user-profile-personal-data-warning-txt {

}

#user-profile-personal-data-edit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25%;
    background-color: violet;
}

#user-profile-personal-data-edit-btn-save {
    display: flex;
    color: var(--light-gray);
    font-size: 1.9em;
    margin-right: 2%;
    cursor: pointer;
    user-select: none;
}

#user-profile-personal-data-edit-btn-save:hover {
    animation: var(--set-blue-color);
}

#user-profile-personal-data-edit-btn-cancel {
    display: flex;
    color: var(--light-gray);
    font-size: 1.9em;
    margin-left: 2%;
    cursor: pointer;
    user-select: none;
}

#user-profile-personal-data-edit-btn-cancel:hover {
    animation: var(--set-orange-color);
}

@media screen and (max-width: 500px) {

    #user-profile-personal-data-edit-label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 25%;
    }

    #user-profile-personal-data-edit-label-txt {
        display: flex;
        text-align: center;
        color: var(--dark-gray);
        font-size: 1.4em;
        user-select: none;
    }

    #user-profile-personal-data-edit-text {
        display: flex;
        width:  95%;
        height: 22%;
    }

    .user-profile-personal-data-edit-text2 {
        display: flex;
        width: 90%;
        height: 22%;
        margin-top: 10%;
    }

    #user-profile-personal-data-edit-btn-save {
        display: flex;
        color: var(--light-gray);
        font-size: 1.9em;
        margin-right: 10%;
        cursor: pointer;
        user-select: none;
    }
    
    
    #user-profile-personal-data-edit-btn-cancel {
        display: flex;
        color: var(--light-gray);
        font-size: 1.9em;
        margin-left: 10%;
        cursor: pointer;
        user-select: none;
    }
}