#cart-address-main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 613px;
    height: auto;
}

#cart-address-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 150px;
}

#cart-address-btn-select {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 45px;
    background-color: var(--logo-green);
    border-radius: var(--main-border-radius);
    cursor: pointer;
}

#cart-address-btn-select-txt {
    display: flex;
    font-size: 1.3em;
    color: #FFFFFF;
    user-select: none;
}

#cart-address-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
}

#cart-address-header-warning-txt {
    display: flex;
    font-size: 1.2em;
    color: var(--logo-orange);
    user-select: none;
}

#cart-address-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
}

@media screen and (max-width: 650px) {

    #cart-address-btn-select {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        height: 45px;
        background-color: var(--logo-green);
        border-radius: var(--main-border-radius);
        cursor: pointer;
    }
}

