#color-input-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
}

#color-input-text-label-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40%;
    /* background-color: aqua; */
}

#color-input-text-label {
    display: flex;
    font-size: 1.3em;
    margin-left: 3.5%;
    color: #7D7D7D;
}

#color-input-text-field-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60%;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #d8d5d5;
    /* background-color: white; */
    background-color: yellow;
    cursor: pointer;
}

#color-input-currency {
    display: flex;
    font-size: 1.5em;
    color: var(--light-gray);
    margin-left: 2%;
    margin-bottom: 0.5%;
    user-select: none;
}

#color-input-text-field {
    display: none;
}