#footer-link-main {
    display: flex;
    width: 60%;
    height: auto;
    margin-bottom: 1%;
    text-decoration: none;
}

#footer-link-txt {
    display: flex;
    font-size: 1.2em;
    text-decoration: none;
    color: #FFFFFF;
}