#exchanges-and-returns-template-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    z-index: 0;
    overflow-x: hidden;
    background: linear-gradient(180deg, #A9DEF9 30%, #FF99C8 100%);
}

#exchanges-and-returns-template-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    /* min-height: 100%; */
    height: 100%;
    flex-shrink: 0;
    background-color: #FFFFFF;
}

#exchanges-and-returns-template-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid var(--light-gray);
}


#exchanges-and-returns-header-txt {
    font-size: 2em;
    color: var(--dark-gray);
    margin-top: 1%;
    user-select: none;
}


#exchanges-and-returns-subheader-txt {
    font-size: 1.2em;
    color: var(--dark-gray);
    margin-top: 0.5%;
    user-select: none;
}

#exchanges-and-returns-template-list {
    display: flex;

    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: auto;
    /* height: 715px;; */
    margin: 0;
    padding-top: 2%;
    flex-shrink: 0;
    min-height: 402px;
}

.exchanges-and-returns-text {
    margin-inline: 10%;
    text-align: start;
    display: flex;
    font-size: 1.4em;
    color: var(--dark-gray);
    text-decoration: none;
    margin-bottom: 2%;
}

.exchanges-and-returns-text-category {
    font-size: 1.7em;
}

.exchanges-and-returns-space {
    margin-left: 12%;
}

@media screen and (max-width: 1250px) {
    #exchanges-and-returns-template-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* min-height: 100%; */
        height: 100%;
        flex-shrink: 0;
        background-color: #FFFFFF;
    }
    
}

@media screen and (max-width: 650px){
    #exchanges-and-returns-template-header-title {
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0%;
    }

    .exchanges-and-returns-template-header-title-spacer {
        display: flex;
        height: 1px;
        width: 40px;
    }

    #exchanges-and-returns-template-header-title-txt {
        display: flex;
        font-size: 2em;
        color: var(--light-gray);
    }
}