#admin-product-list-product-variation-selector-variation-main{
    display: flex;
    flex-shrink: 0;
    margin-right: 2%;
    width: 1.6vw;
    height: 1.6vw;
    border-radius: 50%;
    /* border: 2px solid #000000; */
    cursor: pointer;
    background-color: cadetblue;
}

#admin-product-list-product-variation-selector-variation-notification {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    margin-left: 0.9%;
    margin-top: -0.3%;
    width: 1.1vw;
    height: 1.1vw;
}

#admin-product-list-product-variation-selector-variation-notification-img {
    display: flex;
    width: 100%;
}