#admin-settings-catalog-banners-list-element {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 640px;
    height: 200px;
    flex-shrink: 0;
    margin-top: 1%;
    border-radius: var(--main-border-radius);
    cursor: pointer;
}

#admin-settings-catalog-banners-list-element-img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: inherit;
}

#admin-settings-catalog-banners-list-element:first-child {
    margin-top: 2%;
}

#admin-settings-catalog-banners-list-element:last-child {
    margin-bottom: 2%;
}