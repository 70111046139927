#admin-shipped-orders-order-detail-main {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 70%;
    height: 100%;
    background-color: #FFFFFF;
    border-left: var(--main-border);
}

#admin-shipped-orders-order-detail-order-header {
    display: flex;
    width: 100%;
    height: 10%;
    align-items: center;
    border-bottom: var(--main-border);
    box-sizing: border-box;
}


#admin-shipped-orders-order-detail-order-header-identifier {
    display: flex;
    width: 80%;
    height: 100%;
}


#admin-shipped-orders-order-detail-order-header-identifier-text1 {
    width: fit-content;
    height: fit-content;
    margin-left: 2%;
    margin-right: 1%;
    text-align: left;
    margin-top: 2%;
    font-size: 2.1em;
}

#admin-shipped-orders-order-detail-order-header-identifier-text2 {
    width: fit-content;
    height: fit-content;
    text-align: left;
    margin-top: 2%;
    font-size: 2.1em;
    color: var(--dark-gray);
}

#admin-shipped-orders-order-detail-order-header-cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;
}

#admin-shipped-orders-order-detail-order-header-cancel-btn2 {
    display: flex;
    font-size: 1.4vw;
    color: var(--light-gray);
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
}

#admin-shipped-orders-order-detail-order-header-cancel-btn2:hover {
    animation: var(--set-orange-color);
}

#admin-shipped-orders-order-detail-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 90%;
}


#admin-shipped-orders-order-detail-body-data {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    /* border: 1px solid #d8d5d5; */
    /* background-color: rgb(87, 207, 7); */
}

#admin-shipped-orders-order-detail-body-data-payment {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50%;
    /* background-color: chartreuse; */
}

#admin-shipped-orders-order-detail-body-data-payment-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 15%;
    margin-top: 4%;
    /* background-color: blueviolet; */
}


#admin-shipped-orders-order-detail-body-data-payment-title-text {
    display: flex;
    width: fit-content;
    height: fit-content;
    text-align: left;
    margin-left: 4%;
    font-size: 1.3vw;
    /* background-color: antiquewhite; */
}




#admin-shipped-orders-order-detail-body-data-payment-method-detail1 {
    display: flex;
    width: fit-content;
    height: fit-content;
    overflow: visible;
    align-items: center;
    margin-right: 1%;
    white-space: nowrap;
}

#admin-shipped-orders-order-detail-body-data-payment-method-detail1-text {
    display: flex;
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 1.1vw;
}








#admin-shipped-orders-order-detail-body-data-payment-method-detail2 {
    display: flex;
    min-width: 20%;
    width: fit-content;
    height: fit-content;
    overflow: visible;
    align-items: flex-end;
    background-color: rgb(59, 211, 13);
    margin-right: 1%;
    white-space: nowrap;
}

#admin-shipped-orders-order-detail-body-data-payment-method-detail2-text {
    display: flex;
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 1.1vw;
}

#admin-shipped-orders-order-detail-body-data-shipment {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40%;
}

#admin-shipped-orders-order-detail-body-data-shipment-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 15%;
    margin-top: 4%;
}

#admin-shipped-orders-order-detail-body-data-shipment-title-text {
    display: flex;
    width: fit-content;
    height: fit-content;
    text-align: left;
    margin-left: 4%;
    font-size: 1.3vw;
    /* background-color: antiquewhite; */
}

#admin-shipped-orders-order-detail-body-data-shipment-observations {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 44%;
}

#admin-shipped-orders-order-detail-body-data-shipment-observations-label {
    display: flex;
    width: 17%;
    height: fit-content;
    text-align: left;
    font-size: 1vw;
}

#admin-shipped-orders-order-detail-body-data-shipment-observations-content {
    display: flex;
    width: 80%;
    height: 100%;
    /* background-color: rgb(95, 95, 168); */
}

#admin-shipped-orders-order-detail-body-data-shipment-observations-content-text {
    display: flex;
    width: 100%;
    height: 100%;
    text-align: left;
    font-size: 1.1vw;
}

#admin-shipped-orders-order-detail-body-data-trial {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    border-radius: 0px 0px 4px 4px;
    border-top: 1px solid #d8d5d5;
}

#admin-shipped-orders-order-detail-body-data-trial-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 60%;
    border-radius: var(--main-border-radius);
    cursor: pointer;
    background-color: var(--logo-green);
}

#admin-shipped-orders-order-detail-body-data-trial-btn-text {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 1.2vw;
    color: #FFFFFF;
    user-select: none;
}


#admin-shipped-orders-order-detail-body-products {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    width: 40%;
    height: 100%;
    border-left: var(--main-border);
}


.text-label {
    display: flex;
    width: fit-content;
    height: fit-content;
    text-align: left;
    margin-right: 1%;
    margin-left: 4%;
    font-size: 0.9vw;
    color: #6e6e6e;
    user-select: none;
}

.admin-shipped-orders-order-info-text {
    display: flex;
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 1.1vw;
}

.admin-shipped-orders-order-detail-body-data-payment-client-fields {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 11%;
}

.admin-shipped-orders-order-detail-body-data-shipment-client-fields {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 13.67%;
}
