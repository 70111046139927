#basic-dropdown-main {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
}

.basic-dropdown-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

#basic-dropdown-label-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40%;
    /* background-color: aqua; */
}

#basic-dropdown-label-txt {
    display: flex;
    font-size: 1vw;
    margin-left: 3.5%;
    color: #7D7D7D;
}

.basic-dropdown-value-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60%;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #d8d5d5;
    background-color: white;
}

#basic-dropdown-value-txt {
    display: flex;
    width: 100%;
    height: auto;
    padding: 0 3%;
    font-size: 1vw;
    color: #7D7D7D;
}

#basic-dropdown-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow: visible;
    margin: 0;
    padding: 0;
    list-style: none;
    height: fit-content;
    overflow: scroll;
    max-height: 260px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-bottom-right-radius: var(--main-border-radius);
    border-bottom-left-radius: var(--main-border-radius);

}


@keyframes grow {
    0% {
      height: 0px;
    }
    100% {
      height: auto;
    }
}

@keyframes shrink {
    0% {
        height: 200px;
    }
    100% {
      height: 0px;
    }
}


