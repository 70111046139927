body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	justify-content: center;
	align-items: center;
}

/* html {
	overflow-x: scroll;
} */

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
	justify-content: center;
	align-items: center;
}

textarea {
	resize: none;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}

p {
	margin: 0;
}

:root {
	--gradient-background: linear-gradient(0deg, #A9DEF9 30%, #FF99C8 100%);
	--main-border: 1px solid #d8d5d5; 
	--logo-blue: #5386D9;
	--logo-pink: #EF28C1;
	--logo-green:#24CE7B; 
	--logo-orange:#E86851;
	--main-blue: #A9DEF9;
	--main-pink: #FF99C8;
	--light-gray:#D8D5D5;
	--dark-gray:#7D7D7D;
	--warning-red:#F70000;
	--main-border-radius: 10px;
	--main-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
	--set-blue-color: blueColorAnimation ease 0.2s forwards;
	--set-blue-color2: blueColorAnimation2 ease 0.2s forwards;
	--set-orange-color: orangeColorAnimation ease 0.2s forwards;
	--set-orange-color2: orangeColorAnimation2 ease 0.2s forwards;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    background-color: #FFFFFF !important;
    color: #555 !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #555555 !important;
}

@keyframes blueColorAnimation {
	0% {
		color: var(--light-gray);
	}
	100% {
		color: var(--logo-blue);
	}
}

@keyframes blueColorAnimation2 {
	0% {
		color: var(--dark-gray);
	}
	100% {
		color: var(--logo-blue);
	}
}

@keyframes orangeColorAnimation {
	0% {
		color: var(--light-gray);
	}
	100% {
		color: var(--logo-orange);
	}
}

@keyframes orangeColorAnimation2 {
	0% {
		color: var(--dark-gray);
	}
	100% {
		color: var(--logo-orange);
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}