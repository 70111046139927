#text-field-large-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
}

#text-field-large-text-label-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 20%;
    }

#text-field-large-text-label {
    display: flex;
    font-size: 1.3em;
    margin-left: 3.5%;
    color: #7D7D7D;
    user-select: none;
}

#text-field-large-text-field-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80%;
    border-radius: 8px;
    background-color: (87, 27, 27);
}

#text-field-large-text-field {
    width: 100%;
    height: 100%;
    padding: 0% 2% 0% 3%;
    box-sizing: border-box;
    border: 1px solid #d8d5d5;
    outline: none;
    border-radius: inherit;

    font-weight: 500;
    font-size: 1.3em;
    caret-color: #7D7D7D;
    color: #7D7D7D;
    background-color: transparent;
}