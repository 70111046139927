#admin-menu-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    min-height: 834px;
    z-index: 0;
    background: linear-gradient(180deg, #A9DEF9 30%, #FF99C8 100%);
}

#admin-menu-content-link-container {
    display: flex;
    flex-direction: column;
    width: 65%;
    height: fit-content;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    background-color: #FFFFFF;
}

#admin-menu-content-link-label {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    /* height: 28.57%; */
    height: 150px;
    border-radius: 10px 10px 0px 0px;
}

#admin-menu-content-link-label-txt {
    display: flex;
    font-size: 2.8em;
    user-select: none;
    margin-left: 5%;
    color: var(--light-gray);
}

#admin-menu-content-link-logout-txt {
    display: flex;
    user-select: none;
    font-size: 1.4em;
    margin-top: 2%;
    margin-left: 5%;
    color: var(--light-gray);
    cursor: pointer;
}   

#admin-menu-content-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: fit-content;
    min-height: 500px;
    /* height: 71.43%; */
    list-style: 0;
    border-radius: 0px 0px 10px 10px;
    background-color: #FFFFFF;
}   
