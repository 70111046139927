#admin-product-navigation-main {
    display: flex;
    width: 100%;
    height: 8%;
    flex-shrink: 0;
    overflow-x: scroll;
    box-sizing: border-box;
    border-bottom: var(--main-border);
}

#admin-product-navigation-add-btn {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100%;
    box-sizing: border-box;
    border-right: var(--main-border);
    cursor: pointer;
}

#admin-product-navigation-add-btn-txt {
    display: flex;
    font-size: 1vw;
    color: var(--dark-gray);
    user-select: none;
}

#admin-product-navigation-add-btn-img {
    display: flex;
    width: 11%;
    margin-left: 5%;
}