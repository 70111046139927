#search-field-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #d8d5d5;
    box-sizing: border-box;
    background-color: white;
}

#search-field-text-field {
    display: flex;
    width: 85%;
    height: 100%;
    padding: 0 3%;
    border: none;
    outline: none;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;

    font-size: 1.3em;
    caret-color: #7D7D7D;
    font-family: 'Montserrat', sans-serif;
    color: #7D7D7D;
    /* background-color: transparent; */
    /* background-color: rgb(0, 255, 13); */
}

#search-field-text-field-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9%;
    height: 100%;
    cursor: pointer;
    /* background-color: aqua; */
}

#search-field-text-field-icon {
    display: flex;
    width: 45%;
    user-select: none;
}