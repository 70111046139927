#content-pagination-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
}

.content-pagination-element {
    display: flex;
    margin: 0;
    padding: 0;
    margin-inline: 10px;
    justify-content: center;
    align-items: center;
    border: var(--main-border);
    border-radius: var(--main-border-radius);
    width: 200px;
    height: 40px;
    color: var(--dark-gray);
    cursor: pointer;
    font-weight: 500;
    user-select: none;
}

#content-pagination-display-element {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: var(--main-border);
    border-radius: var(--main-border-radius);
    color: var(--dark-gray);
    font-weight: 500;
    user-select: none;
}