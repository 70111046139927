#product-details-freight-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}


#product-details-freight-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#product-details-freight-label {
    display: flex;
    align-items: center;
    width: auto;
    height: 40%;
    font-size: 1em;
    color: var(--light-gray);
}

#product-details-freight-input-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60%;
}

#product-details-freight-input {
    display: flex;
    width: 30%;
    height: 100%;
    font-size: 1.3em;
    /* padding-left: 3.5%; */
    border: none;
    outline: none;
    caret-color: #7D7D7D;
    color: #7D7D7D;
}

#product-detail-freight-btn-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    height: 100%;
}

#product-detail-freight-btn {
    display: flex;
    cursor: pointer;
    color: var(--dark-gray);
    user-select: none;
}

#product-detail-freight-btn:hover {
    animation: var(--set-blue-color2);
}

#product-detail-freight-result {
    display: flex;
    height: 100%;
    width: 40%;
    justify-content: flex-start;
    align-items: center;
    color: #24CE7B;
    background-color: 40%;

}