#user-profile-status-tracker-item-main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: auto;
    height: 70%;
    /* background-color: rgb(65, 224, 2); */
}

#user-profile-status-tracker-item-trace {
    display: flex;
    width: 4.3em;
    height: 1px;
    border-radius: 10px;
    margin-right: 6%;
    margin-left: 6%;
    background-color: var(--light-gray);
}

#user-profile-status-tracker-item-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.3em;
    height: 100%;
    margin-right: 5%;
    /* background-color: blueviolet; */
}

#user-profile-status-tracker-item-img {
    display: flex;
    width: 80%;
}


#user-profile-status-tracker-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    height: 100%;
    /* background-color: burlywood; */
}

#user-profile-status-tracker-name-txt {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 1.3em;
    font-weight: 400;
    color: var(--light-gray);
    user-select: none;
    /* background-color: aqua; */
    
}