#file-upload-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

#file-upload-field {
    display: none;
}

#file-upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

#file-upload-btn-txt {
    display: flex;
    color: #FFFFFF;
    font-size: 1.2vw;
    font-weight: 500;
    user-select: none;
}
