#header-cart-main {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 12%; */
    height: 40%;
    width: 57px;
    border-radius: var(--main-border-radius);
    border: var(--main-border);
    cursor: pointer;
    flex-shrink: 0;
}

#header-cart-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

#header-cart-img {
    display: flex;
    width: 70%;
    user-select: none;
}

#header-cart-produc-quantity-txt {
    position: absolute;
    display: flex;
    margin-top: 0.57%;
    font-size: 1.1em;
    font-weight: 500;
    color: var(--light-gray);
    user-select: none;
}

@media screen and (max-width: 805px) {
    #header-cart-main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 9vw;
        width: 9vw;
        margin-right: 5%;
        border-radius: var(--main-border-radius);
        border: var(--main-border);
        cursor: pointer;
        flex-shrink: 0;
    }

    #header-cart-produc-quantity-txt {
        position: absolute;
        display: flex;
        margin-top: 2%;
        font-size: 3vw;
        font-weight: 500;
        color: var(--light-gray);
        user-select: none;
    }
}