#header-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 129.35px;
    flex-shrink: 0;
    z-index: 99999;
}

#header-shadow {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 99999;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}


#header-logo-link {
    /* width: 8vw;
    margin-left: 30%;
    cursor: pointer;
    user-select: none; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 100%;
    background-color: #FFFFFF;
}


#header-logo {
    display: flex;
    height: 75%;
    user-select: none;
}

#header-general-fields-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    height: 100%;
    background-color: #FFFFFF;    
}

#header-general-fields-search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 65%;
}

#header-general-fields-search-container {
    display: flex;
    width: 90%;
    height: 60%;
}

#header-general-fields-category-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 35%;
}

#header-user-fields-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
}

#header-mobile-menu {
    z-index: 9999999;
    position: absolute;
    top: 129.35px;
    width: 100%;
    min-height: 835px;
    height: auto;
    background-color: #000000d2;
}

.header-mobile-menu-hide {
    display: none;
}

.header-mobile-menu-show {
    display: flex;
    flex-direction: column;
    animation: header-mobile-menu-slide 0s linear forwards;
}

#header-mobile-menu-search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
}

#header-mobile-menu-search {
    display: flex;
    width: 95%;
    height: 70%;
}

#header-mobile-menu-categories-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.header-mobile-menu-category-container {
    display: flex;
    width: 50%;
    height: auto;
}

.header-mobile-menu-category {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.header-mobile-menu-category-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-bottom: 3%;
    margin-top: 3%;
    /* background-color: turquoise; */
}

.header-mobile-menu-category-title-txt {
    display: flex;
    font-size: 1.8em;
    color: var(--light-gray);
    cursor: pointer;
    user-select: none;
    text-decoration: none;
}

.header-mobile-menu-category-entry {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    /* background-color: rgb(143, 14, 14); */
}

.header-mobile-menu-category-entry-txt {
    display: flex;
    font-size: 1.1em;
    color: var(--light-gray);
    cursor: pointer;
    user-select: none;
    text-decoration: none;
}


@keyframes header-mobile-menu-slide {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

@media screen and (max-width: 1121px) {
    #header-general-fields-category-container {
        display: none;
        flex-direction: row;
        width: 100%;
        height: 35%;
    }

}
@media screen and (max-width: 805px) {
    #header-logo {
        display: flex;
        height: 14vw;
        user-select: none;
    }

    #header-general-fields-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 40%;
        height: 100%;
        background-color: #FFFFFF;    
    }
    #header-user-fields-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 35%;
        height: 100%;
    }

    #header-general-fields-search-container {
        display: none;
        width: 0;
        height: 0;
        background-color: #A8DEF8;
    }
}