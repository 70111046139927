#page404-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    overflow: scroll;
}

#page404-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25%;
}

#page404-logo-img-container {
    display: flex;
    height: 100%;
}

#page404-logo-img {
    display: flex;
    width: 100%;
    height: 100%;
}

#page404-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;
}

#page404-info-txt {
    display: flex;
    text-align: center;
    font-size: 3em;
    color: var(--dark-gray);
    user-select: none;
}

@media screen and (max-width: 1250px) {
    #page404-info-txt {
        display: flex;
        text-align: center;
        font-size: 2em;
        color: var(--dark-gray);
    }
}

@media screen and (max-width: 600px) {
    #page404-info-txt {
        display: flex;
        text-align: center;
        font-size: 1.4em;
        color: var(--dark-gray);
    }
}