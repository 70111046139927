#admin-settings-catalog-banners-new {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    width: 100%;
    height: 88%;
}

.admin-settings-catalog-banners-new-info-input-container {
    display: flex;
    width: 500px;
    height: 90px;
    margin-left: 2%;
    margin-bottom: 1%;
}

#admin-settings-catalog-banners-new-info-image-input-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 50%;
    margin-left: 2%;
}

#admin-settings-catalog-banners-new-info-image-input-label {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 1.3em;
    margin-left: 1.5%;
    margin-top: 1%;
    margin-bottom: 1%;
    color: #7D7D7D;
    user-select: none;
    /* background-color: violet; */
}

#admin-settings-catalog-banners-new-info-image-input {
    display: flex;
    width: 100%;
    height: 85%;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #d8d5d5;
    box-sizing: border-box;
    
}

#admin-settings-catalog-banners-new-info-image {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: inherit;
    user-select: none;
}