#register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 672px;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.2s linear;
}

#register-logo-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 10%;
}

#register-back-btn {
    display: none;
    width: 2.5em;
    margin-right: 7%;
    user-select: none;
    cursor: pointer;
}

#register-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height:auto;
}

.register-input-container {
    display: flex;
    width: 75%;
    height: 80px;
    margin-bottom: 25px;
}

.register-input-container:first-child {
    display: flex;
    width: 75%;
    margin-top: 2%;
    /* background-color: bisque; */
}

#register-warning {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    height: 61px;
}

#register-warning-txt {
    display: flex;
    text-align: center;
    user-select: none;
    font-size: 1.2em;
    color: var(--logo-orange);
    animation: fade 0.5s
}

#register-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 13%;
}

#register-btns-register {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 46px;
    border-radius: var(--main-border-radius);
    margin-bottom: 2%;
    cursor: pointer;
    background-color: var(--logo-green);
}

#register-btns-register-txt {
    display: flex;
    font-size: 1.2em;
    font-weight: 500;
    color: #ffffff;
    user-select: none;
}

#register-btns-login {
    display: flex;
    font-size: 1.3em;
    cursor: pointer;
    color: #7D7D7D;
    user-select: none;
}

#register-btns-login:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media screen and (max-width: 650px) {

    #register-back-btn {
        display: flex;
    }

    .register-input-container {
        display: flex;
        width: 95%;
        height: 65px;
        margin-bottom: 25px;
    }
    
    .register-input-container:first-child {
        display: flex;
        width: 95%;
        height: 65px;
        margin-bottom: 25px;
    }

    .register-input-container:last-child {
        display: flex;
        width: 95%;
        height: 65px;
        margin-bottom: 0;
    }

    #register-warning-message {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
    }

    #register-btns-register {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 46px;
        margin-top: 5%;
        cursor: pointer;
        border-radius: var(--main-border-radius);
        background-color: var(--logo-green);
    }
    
    #register-btns-login {
        display: none;
        font-size: 1.3em;
        cursor: pointer;
        color: #7D7D7D;
        user-select: none;
    }
}