#recover-page-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background:  linear-gradient(0deg, #A9DEF9 30%, #FF99C8 100%);
}

#recover-page-container {
    display: flex;
    flex-direction: column;
    width: 672px;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.2s linear;
}

#recover-page-logo-container {
    display: flex;
    height: 25%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#recover-page-logo-link {
    display: flex;
    height: fit-content;
    width: 25%;
    justify-content: center;
    align-items: center;
}

#recover-page-logo {
    display: flex;
    width:  100%;
}

#recover-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 75%;
    border-radius: inherit;
}

.recover-page-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 80px;
    margin-bottom: 25px;
}

.recover-page-input-container:first-child {
    display: flex;
    width: 75%;
    height: 70px;
    margin-top: 5%;
    margin-bottom: 12%;
}

#recover-page-text {
    display: flex;
    font-size: 2em;
    color: var(--light-gray);
    user-select: none;
}

#recover-page-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
}

#recover-page-warning-txt {
    display: flex;
    user-select: none;
    font-size: 1.2em;
    color: var(--logo-orange);
    animation: fade 0.5s
}

#recover-page-input-container-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25%;
}

#recover-page-input-container-btn-confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 46px;
    border-radius: var(--main-border-radius);
    cursor: pointer;
    background-color: var(--logo-green);
}

#recover-page-input-container-btn-confirm-txt {
    display: flex;
    font-size: 1.2em;
    font-weight: 500;
    color: #ffffff;
    user-select: none;
}

@media screen and (max-width: 650px) {
    .recover-page-input-container {
        display: flex;
        width: 95%;
        height: 65px;
        margin-bottom: 25px;
    }
    
    .recover-page-input-container:first-child {
        display: flex;
        width: 75%;
        height: 70px;
        margin-top: 2%;
        margin-bottom: 15%;
    }

    #recover-page-warning-message {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
    }

    #recover-page-input-container-btn-confirm {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 46px;
        margin-top: 5%;
        cursor: pointer;
        border-radius: var(--main-border-radius);
        background-color: var(--logo-green);
    }

}