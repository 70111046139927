#admin-product-size-list-size-main {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    width: 90%;
    height: 12%;
    background-color: #FFFFFF;
    margin-top: 2%;
    border-radius: var(--main-border-radius);
    box-sizing: border-box;
    border: var(--main-border);
}

#admin-product-size-list-size-main:last-child {
    margin-bottom: 6%;
}

#admin-product-size-list-size-activated {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 13%;
    height: 100%;
}

#admin-product-size-list-size-size {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 35%;
    height: 100%;
}

#admin-product-size-list-size-stock {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 35%;
    height: 100%;
}

#admin-product-size-list-size-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17%;
    height: 100%;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: #FFFFFF;
    border-left: var(--main-border);
    box-sizing: border-box;
}

#product-item-size-list-size-element-delete-btn {
    display: flex;
    width: 35%;
    cursor: pointer;
    transition-duration: 0.1s;
    transform: scale(1);
    user-select: none;
}

#product-item-size-list-size-element-delete-btn:hover {
    display: flex;
    transition-duration: 0.1s;
    transform: scale(1.3);
}