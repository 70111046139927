#admin-product-list-header-main {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 10%;
    justify-content: space-between;
    background-color: var(--light-gray);
    border-bottom: var(--main-border);
    box-sizing: border-box;
    z-index: 1;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

#admin-product-list-header-searchfield {
    display: flex;
    width: 40%;
    height: 60%;
    margin-left: 2%;
}


#admin-product-list-header-searchfield-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #d8d5d5;
    box-sizing: border-box;
    background-color: white;
}

#admin-product-list-header-searchfield-text-field {
    display: flex;
    width: 85%;
    height: 100%;
    padding: 0 3%;
    border: none;
    outline: none;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;

    font-size: 1.3em;
    caret-color: #7D7D7D;
    font-family: 'Montserrat', sans-serif;
    color: #7D7D7D;
}

#admin-product-list-header-category-filter {
    display: flex;
    width: 15%;
    height: 60%;
    margin-left: 2%;
}

#admin-product-list-header-category-filter-dropdown {
    display: flex;
    width: 70%;
    height: 100%;
}
#admin-product-list-header-category-filter-selector {
    display: flex;
    width: 30%;
    height: 100%;
}

#admin-product-list-header-new-product {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    /* background-color: yellowgreen; */
    /* margin-left: 30%; */
}

#admin-product-list-header-new-product-btn {
    display: flex;
    font-size: 1.2vw;
    color: #FFFFFF;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
}

#admin-product-list-header-new-product-btn:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

/* #product-new-header-name {
    display: flex;
    align-items: center;
    width: 55%;
    height: 100%;
}

#product-new-header-name-input {
    display: flex;
    width: 70%;
    height: 70%;
    font-size: 1.2vw;
    margin-left: 1%;
    padding: 0 1.5%;
    border: none;
    outline: none;
    border-radius: 5px;
    caret-color: #FFFFFF;
    color: #FFFFFF;
    background-color: #5d92e8;
}


#product-new-header-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
    height: 100%;
}

.product-new-header-btn {
    display: flex;
    width: auto;
    height: auto;
    font-size: 1.2vw;
    margin-left: 4%;
    cursor: pointer;
    user-select: none;
    color: #FFFFFF;
    font-weight: 500;
}

.product-new-header-btn:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

#product-new-header-btns-delete {
    margin-right: 5%;
} */