#user-profile-menu-entry-main {
    display: flex;
    margin-right: 1%;
    margin-left: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 350px;
    height: 105px;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #d8d5d5;
    cursor: pointer;
}

#user-profile-menu-entry-link {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    border-radius: inherit;
    text-decoration: none;
}

#user-profile-menu-entry-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
    /* background-color: yellowgreen; */
}

#user-profile-menu-entry-icon {
    display: flex;
    width: 65%;
    user-select: none;
}

#user-profile-menu-entry-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    height: 100%;
    /* background-color: rgb(131, 7, 7); */
}

#user-profile-menu-entry-info-title {
    display: flex;
    font-size: 1.3em;
    margin-left: 3%;
    user-select: none;
}

#user-profile-menu-entry-info-description {
    display: flex;
    font-size: 1em;
    margin-top: 2%;
    margin-left: 3%;
    user-select: none;
}
