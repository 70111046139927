#product-page-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 834px;
    z-index: 0;
    overflow-x: hidden;
    background: linear-gradient(180deg, #A9DEF9 30%, #FF99C8 100%);
}

#product-page-content {
    display: flex;
    flex-direction: column;
    width: 1248px;
    height: 100%;
    flex-shrink: 0;
    background-color: #FFFFFF;
}

#product-page-header {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
    height: 150px;
}

#product-page-header-title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 100%;
    /* background-color: blanchedalmond; */
}

#product-page-header-navigation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42%;
    height: 100%;
}

#product-page-header-title-txt {
    display: flex;    
    font-size: 3vw;
    color: #d8d5d5;
}

#product-page-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 684px;
}

#product-page-body-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 74%;
    /* background-color: blue; */
}

#product-page-body-description {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 26%;
}

#product-page-body-description-txt {
    display: flex;
    text-align: justify;
    width: 90%;
    height: auto;
    font-size: 1vw;
    color: var(--dark-gray);
}

@media screen and (max-width: 1250px) {

    #product-page-header {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        height: 80px;
    }

    #product-page-header-navigation-container {
        display: none;
        justify-content: center;
        align-items: center;
        width: 42%;
        height: 100%;
    }

    #product-page-main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        z-index: 0;
        background: linear-gradient(180deg, #A9DEF9 30%, #FF99C8 100%);
    }

    #product-page-content {
        display: flex;
        flex-direction: column;
        width: 896px;
        height: auto;
        flex-shrink: 0;
    }

    #product-page-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 896px) {
    #product-page-header {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        height: 80px;
    }

    #product-page-main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        z-index: 0;
        background: linear-gradient(180deg, #A9DEF9 30%, #FF99C8 100%);
    }

    #product-page-content {
        display: flex;
        flex-direction: column;
        width: 500px;
        height: auto;
        flex-shrink: 0;
    }

    #product-page-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 500px) {

    #product-page-header {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        height: 0px;
    }
    
    #product-page-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        flex-shrink: 0;
    }

    #product-page-body-description {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        min-height: 300px;
        margin-bottom: 30px;
        margin-top: 40px;
    }

    #product-page-body-description-txt {
        display: flex;
        text-align: justify;
        width: 90%;
        height: auto;
        font-size: 1em;
        color: var(--dark-gray);
    }

}

