#admin-product-list-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 754px;
}

#admin-product-list-body {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    align-items: center;
    width: 100%;
    height: 90%;
    background-color: #FFFFFF;
}
