#admin-page-template-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    z-index: 0;
    background: linear-gradient(180deg, #A9DEF9 30%, #FF99C8 100%);
}

#admin-page-template-content {
    display: flex;
    flex-direction: column;
    width: 95%;
    /* min-height: 100%; */
    height: 100%;
    flex-shrink: 0;
}

#admin-page-template-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    background-color: #FFFFFF;
}

#admin-page-template-header-img-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 8%;
    height: 100%;
    /* background-color: azure; */
}

#admin-page-template-header-img {
    display: flex;
    margin-top: 10%;
    width: 100%;
    user-select: none;
}

#admin-page-template-header-title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 100%;
    /* background-color: blanchedalmond; */
}

#admin-page-template-header-navigation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42%;
    height: 100%;
    /* background-color: rgb(194, 123, 17); */
}

#admin-page-template-header-title-txt {
    display: flex;    
    font-size: 3vw;
    user-select: none;
    color: #d8d5d5;
}

#admin-page-template-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* flex-wrap: wrap; */
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 754px;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
}