#admin-settings-category-page-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    /* background-color: rgb(156, 102, 1); */
}


#admin-settings-category-page-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: #FFFFFF;
}

#admin-settings-category-page-labe-txt {
    display: flex;
    font-size: 1.3vw;
    color: var(--light-gray);
    user-select: none;
}

#admin-settings-category-page-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
}

#admin-settings-category-page-selector-container {
    display: flex;
    width: 30%;
    margin: 70px;
    height: 60px;
}

#admin-settings-category-page-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
}


#admin-settings-category-page-image {
    display: flex;
    width: 70%;
    border-radius: var(--main-border-radius);
}

#admin-settings-category-page-image-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 10%;
}

#admin-settings-category-page-image-warning-txt {
    display: flex;
    text-align: center;
    font-size: 1.2vw;
    color: var(--logo-orange);
    animation: fade2 ease infinite alternate 2.4s;
}

#admin-settings-category-page-image-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
}

#admin-settings-category-page-image-upload-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 60%;
    background-color: var(--logo-green);
    border-radius: var(--main-border-radius);
    cursor: pointer;
}

#admin-settings-category-page-image-upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--logo-green);
    border-radius: var(--main-border-radius);
    cursor: pointer;
}

#admin-settings-category-page-image-upload-btn-txt {
    display: flex;
    font-size: 1.2vw;
    color: #FFFFFF;
    user-select: none;
    font-weight: 500;
}

#admin-settings-category-page-image-btn {
    display: flex;
    width: 100%;
    height: 20%;
}
