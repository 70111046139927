#user-profile-cart-status-tracker {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70px;
    overflow-x: hidden;
    /* background-color: aquamarine; */
}

@media screen and (max-width: 1100px) {
    #user-profile-cart-status-tracker {
        display: none;
        justify-content: center;
        width: 100%;
        height: 70px;
        /* background-color: aquamarine; */
    }
}