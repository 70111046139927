#product-edit-navigation-btn-main {
    display: flex;
    width: 15%;
    height: 100%;
    box-sizing: border-box;
    border-right: var(--main-border);
    cursor: pointer;
    flex-shrink: 0;
}

#product-edit-navigation-btn-name {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 75%;
    height: 100%;
}

#product-edit-navigation-btn-name-txt {
    display: flex;
    font-size: 1vw;
    color: var(--dark-gray);
}

#product-edit-navigation-btn-color {
    display: flex;
    width: 1.4vw;
    height: 1.4vw;
    border-radius: 50%;
    margin-left: 5%;
}

#product-edit-navigation-btn-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 100%;
}

#product-edit-navigation-btn-notification-img {
    display: flex;
    width: 60%;
}