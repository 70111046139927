#admin-product-size-list-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

#admin-product-size-list-main::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */ 
}

#admin-product-size-list-new-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 90%;
    height: 50px;
    margin-bottom: 6%;
    margin-top: 10px;
    border-radius: var(--main-border-radius);
    border: var(--main-border);
    background-color: var(--light-gray);
    cursor: pointer;
}

#admin-product-size-list-new-btn-txt {
    display: flex;
    color: #FFFFFF;
    font-size: 1.2vw;
    font-weight: 500;
    user-select: none;
}