#card-address-list-element-main {    
    display: flex;
    width: 95%;
    height: auto;
    flex-shrink: 0;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: var(--main-border-radius);
    border: 1px solid #d8d5d5;
    margin-bottom: 2%;
}

#card-address-list-element-resume {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100px;
    height: auto;
}

#card-address-list-element-resume-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 5%;
    min-width: 55.7833px;
    height: 100px;
    margin-left: 1%;
}

#card-address-list-element-resume-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
    min-height: 100px;
    height: auto;
}

#card-address-list-element-resume-info-name {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 40%;
    /* background-color: aqua; */
}


#card-address-list-element-resume-info-name-txt {
    display: flex;
    font-size: 1.5em;
    font-weight: 500;
    color: var(--dark-gray);
}

#card-address-list-element-resume-info-details {
    display: flex;  
    flex-wrap: wrap;
    justify-items: flex-start;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%;
    min-height: 60px;
    height: auto;
    flex-shrink: 0;
}

.card-address-list-element-resume-info-details-txt {
    display: flex;
    font-size: 1.3em;
    margin-right: 1%;
}

#card-address-list-element-resume-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 167.4px;
    width: 15%;
    height: 100px;
}

#card-address-list-element-resume-info-details-edit {
    display: flex;
    color: var(--light-gray);
    font-weight: 500;
    user-select: none;
    font-size: 1.3em;
    margin-left: 10%;
    cursor: pointer;
}

#card-address-list-element-resume-info-details-edit:hover {
    text-decoration: underline;
    color: var(--logo-blue);
}

#card-address-list-element-resume-info-details-save {
    display: flex;
    color: var(--light-gray);
    font-weight: 500;
    user-select: none;
    font-size: 1.3em;
    margin-right: 15%;
    margin-left: 10%;
    cursor: pointer;
}

#card-address-list-element-resume-info-details-save:hover {
    text-decoration: underline;
    color: var(--logo-blue);
}

#card-address-list-element-edit {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
}

.card-address-list-element-edit-fields-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 100%;
    margin-bottom: 3%;
}

.card-address-list-element-edit-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 81.95px;
    margin-top: 8%;
}


@media screen and (max-width: 700px) {

    #card-address-list-element-resume-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5%;
        min-width: 55.7833px;
        height: 100px;
        margin-left: 1%;
    }

    #card-address-list-element-resume-info-name-txt {
        display: flex;
        font-size: 3vw;
        font-weight: 500;
        color: var(--dark-gray);
    }

    .card-address-list-element-resume-info-details-txt {
        display: flex;
        font-size: 2.4vw;
        margin-right: 1%;
    }



    #card-address-list-element-resume-info-details-edit {
        display: flex;
        color: var(--light-gray);
        font-weight: 500;
        user-select: none;
        font-size: 4.3vw;
        margin-left: 10%;
        cursor: pointer;
    }

    #card-address-list-element-resume-info-details-save {
        display: flex;
        color: var(--light-gray);
        font-weight: 500;
        user-select: none;
        font-size: 4.3vw;
        margin-right: 15%;
        margin-left: 10%;
        cursor: pointer;
    }

    #card-address-list-element-edit {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    .card-address-list-element-edit-fields-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 50%;
        margin-bottom: 0;
    }

    .card-address-list-element-edit-fields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        height: 60px;
        margin-top: 5%;
    }

    .card-address-list-element-edit-fields-special {
        margin-bottom: 7%;
    }
}