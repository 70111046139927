#catalog-main {
    display: flex;
    /* overflow-y: scroll; */
    flex-direction: column;
    width: 100%;
    height: max-content;
    overflow-x: hidden;
}

#catalog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-color: --background;
}

#catalog-content-highlights-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
}

.catalog-content-highlights-carousel {
    width: 100%;
    height: auto;
}

#catalog-content-categories {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    width: 100%;
    height: 140px;
    background: linear-gradient(90deg, #A9DEF9 30%, #FF99C8 100%);
}



#catalog-content-products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
    height: auto;
    padding-top: 1%;
}

#aaa {
    display: flex;
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 1250px) {
    #catalog-content-products {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 95%;
        height: auto;
        padding-top: 1%;
    }
}

.slider-offset {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}