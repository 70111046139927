#admin-product-header-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
    box-shadow: var(--main-shadow);
    z-index: 1;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background-color: var(--logo-blue);



}

.animated-background {
    background-image: linear-gradient(to left, #5386D9, #EF28C1);
    background-size: 1000%;
    animation: backgroundAnimation 0.3s linear forwards
}

.animated-background-reverse {
    background-image: linear-gradient(to left, #5386D9, #EF28C1);
    background-size: 1000%;
    animation: backgroundAnimation 0.3s linear forwards reverse
}

@keyframes backgroundAnimation {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 100%;
    }
}

#admin-product-header-name {
    display: flex;
    align-items: center;
    width: 45%;
    height: 100%;
}

#admin-product-header-name-input {
    display: flex;
    width: 95%;
    height: 70%;
    font-size: 1.2vw;
    margin-left: 1%;
    padding: 0 1.5%;
    border: none;
    outline: none;
    background-color: #5d92e8;
    border-radius: 5px;
    caret-color: #FFFFFF;
    color: #FFFFFF;
}

#admin-product-header-error {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 35%;
    height: 100%;
}

#admin-product-header-error-txt {
    display: flex;
    margin-left: 10%;
    font-weight: 0.9vw;
    color: #FFFFFF;
    font-weight: 500;
    animation: fade ease infinite alternate 2.4s;
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

#admin-product-header-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 20%;
    height: 100%;
}

.admin-product-header-btn {
    display: flex;
    width: auto;
    height: auto;
    font-size: 1.2vw;
    margin-left: 4%;
    cursor: pointer;
    user-select: none;
    color: #FFFFFF;
    font-weight: 500;
    text-decoration: none;
}

.admin-product-header-btn:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

#admin-product-header-btns-delete {
    margin-right: 15%;
}

