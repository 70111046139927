#footer-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    flex-shrink: 0;
    background-color: var(--main-pink);
    border-top: 1px solid #FFFFFF;
}

#footer-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35%;
    background-color: var(--main-pink);
}

#footer-social-label-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 40%;
}

#footer-social-label-txt {
    display: flex;
    font-size: 1.4em;
    color: #FFFFFF;
    margin-bottom: 0.3%;
}

#footer-social-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 60%;
    /* background-color: white; */
}

.footer-social-link {
    width: 2.2%;
    height: 40%;
    margin-top: 0.3%;
}

.footer-social-link-img {
    display: flex;
    width: 100%;
}

#footer-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55%;
}

#footer-info-map {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 22%;
    height: 100%;
    /* background-color: rgb(13, 173, 221);   */
}

.footer-info-label-txt {
    display: flex;
    width: 60%;
    height: auto;
    color: #FFFFFF;
    font-size: 1.3em;
    margin-bottom: 1.5%;
    font-weight: 500;
    /* background-color: aqua; */
}

#footer-info-payment-methods {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 22%;
    height: 100%;  
}

#footer-info-institutional {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 22%;
    height: 100%;
}

#footer-info-customer-support {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 22%;
    height: 100%;
}


#footer-developer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    /* background-color: rgb(69, 104, 22); */
}

@media screen and (max-width: 1084px) {
    #footer-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 1200px;
        flex-shrink: 0;
        background-color: var(--main-pink);
        border-top: 1px solid #FFFFFF;
    }

    #footer-social {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 10%;
        background-color: var(--main-pink);
    }

    #footer-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 86%;
    }

    #footer-info-map {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        height: 25%;
    }

    #footer-info-payment-methods {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        height: 25%;
    }
    
    #footer-info-institutional {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        height: 25%;
    }

    #footer-info-customer-support {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        height: 25%;
    }

    #footer-developer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 4%;
        background-color: var(--light-gray);
    }

    .footer-info-label-txt {
        display: flex;
        width: 60%;
        height: auto;
        color: #FFFFFF;
        font-size: 1.3em;
        margin-bottom: 3%;
        margin-top: 3%;
        font-weight: 500;
    }
}