#cart-authentication-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 613px;
}

#cart-authentication-adversiment {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15%;
}

#cart-authentication-adversiment-txt {
    display: flex;
    text-align: center;
    font-size: 1.6em;
    color: var(--light-gray);
    margin-bottom: 1%;
    user-select: none;
}

#cart-authentication {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 85%;
}


@media screen and (max-width: 650px) {

    #cart-authentication-main {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    #cart-authentication-adversiment {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 135px;
    }

    #cart-authentication-adversiment-txt {
        display: flex;
        text-align: center;
        font-size: 1.3em;
        color: var(--light-gray);
        margin-bottom: 3%;
        user-select: none;
    }

    #cart-authentication {
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        height: auto;
    }
}