#cart-checkout-main {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    height: auto;
    /* height: 613px; */
}

#cart-checkout-primary {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 75%;
    height: auto;
}


#cart-checkout-product-list-btns {
    display: flex;
    width: 95%;
    height: 100px;
    /* background-color: #792d2d; */
}

#cart-checkout-product-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    /* background-color: #813e3e; */
}

#cart-checkout-content-secondary {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: auto;
    margin-right: 2%;
}


#cart-checkout-content-sub-total {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    border-radius: var(--main-border-radius);
    border: 1px solid #d8d5d5;
}

#cart-checkout-content-subtotal-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70%;
}

#cart-checkout-content-sub-total-info-label-txt {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 1.7em;
    margin-top: 10px;
    color: var(--light-gray);
    user-select: none;
}

#cart-checkout-content-sub-total-info-value-txt {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 2.3em;
    font-weight: 600;
    margin-top: 10px;
    color: #1F9050;
}

#cart-checkout-content-subtotal-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;
}

#cart-checkout-content-sub-total-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 46px;
    border-radius: var(--main-border-radius);
    background-color: var(--logo-green);
    cursor: pointer;
}

#cart-checkout-content-sub-total-btn-txt {
    display: flex;
    font-size: 1.2em;
    font-weight: 500;
    user-select: none;
    color: #FFFFFF;
}

#cart-checkout-content-coupom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    margin-right: 2%;
    border-radius: var(--main-border-radius);
    border: 1px solid #d8d5d5;
    margin-top: 20px;
}

#cart-checkout-content-coupom-label {
    display: flex;
    width: 100%;
    height: 50%;
}

#cart-checkout-content-coupom-input-container {
    display: flex;
    width: 100%;
    height: 50%;
    background-color: rgb(255, 115, 0);
    
}

#cart-checkout-empty-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 300px;
}

#cart-checkout-empty-cart-message-txt {
    display: flex;
    text-align: center;
    font-size: 2em;
    color: var(--light-gray);
    user-select: none;
}

@media screen and (max-width: 800px) {
    #cart-checkout-main {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    #cart-checkout-primary {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: auto;
    }
    
    #cart-checkout-content-secondary {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin-right: 0%;
    }

    #cart-checkout-content-sub-total {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 15px;
        width: 95%;
        height: 200px;
        box-sizing: border-box;
        border-radius: var(--main-border-radius);
        border: 1px solid #d8d5d5;
    }

    #cart-checkout-content-sub-total-info-label-txt {
        display: flex;
        width: fit-content;
        height: fit-content;
        font-size: 1.6em;
        margin-top: 10px;
        color: var(--light-gray);
        user-select: none;
    }
    
    #cart-checkout-content-sub-total-info-value-txt {
        display: flex;
        width: fit-content;
        height: fit-content;
        font-size: 2.2em;
        font-weight: 600;
        margin-top: 10px;
    }

    #cart-checkout-content-subtotal-btn-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 30%;
    }
    
}