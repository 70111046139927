#user-profile-order-card-main {
    display: flex;
    flex-direction: column;
    width: 90%;
    min-height: 80px;
    flex-shrink: 0;
    margin-top: 1%;
    margin-bottom: 0.5%;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #d8d5d5;    
}

#user-profile-order-card-main:last-child {
    display: flex;
    flex-direction: column;
    width: 90%;
    min-height: 80px;
    flex-shrink: 0;
    margin-top: 1%;
    margin-bottom: 3%;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #d8d5d5;    
}

#user-profile-order-card-main:first-child {
    display: flex;
    flex-direction: column;
    width: 90%;
    min-height: 80px;
    flex-shrink: 0;
    margin-top: 3%;
    margin-bottom: 0.5%;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #d8d5d5;    
}

#user-profile-order-card-resume {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
}

#user-profile-order-card-resume-number {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    justify-content: center;
}

#user-profile-order-card-resume-status {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    justify-content: center;
}

#user-profile-order-card-resume-date {
    display: flex;
    flex-direction: column;
    width: 16%;
    height: 100%;
    justify-content: center;
}

#user-profile-order-card-resume-value {
    display: flex;
    flex-direction: column;
    width: 15%;
    height: 100%;
    justify-content: center;
}

#user-profile-order-card-resume-shipment-code {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 24%;
    height: 100%;
}

#user-profile-order-card-resume-open-detail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 5%;
    height: 100%;
}

#user-profile-order-card-resume-open-detail-img {
    display: flex;
    width: 65%;
    cursor: pointer;
    user-select: none;
}

.user-profile-order-card-resume-label {
    display: flex;
    font-size: 1.2em;
    color: #d8d5d5;
    margin-left: 10%;
    user-select: none;
}

.user-profile-order-card-resume-info {
    display: flex;
    font-size: 1em;
    color: #000000;
    margin-top: 3%;
    margin-left: 10%;
}


#user-profile-order-card-detail {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

#user-profile-order-card-detail-products {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

#user-profile-order-card-detail-products-list {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
    height: auto;
}

.user-profile-card-detail-products-list-element {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
}

.user-profile-card-detail-products-list-element-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
}

.user-profile-card-detail-products-list-element-img {
    display: flex;
    height: 95%;
    margin-left: 2%;
    border-radius: var(--main-border-radius);
}

.user-profile-card-detail-products-list-element-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 100%;
}

.user-profile-card-detail-products-list-element-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 95%;
    width: 95%;
}

.user-profile-card-detail-products-list-element-info-name-txt {
    display: flex;
    font-size: 1.2em;
    width: 100%;
    color: var(--dark-gray);
}

.user-profile-card-detail-products-list-element-info-txt {
    display: flex;
    width: 100%;
    font-size: 1em;
    color: var(--dark-gray);
    margin-top: 3%;
}


/* #user-profile-order-card-detail-payment {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    height: 100%;
    box-sizing: border-box;
}


#user-profile-order-card-detail-payment-info-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
    margin-top: 30px;
}

.user-profile-order-card-detail-payment-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 60px;
    margin-bottom: 1%;
}

.user-profile-order-card-detail-payment-info-label-txt {
    display: flex;
    user-select: none;
    color: var(--light-gray);
    font-size: 1.3em;
}

.user-profile-order-card-detail-payment-info-txt {
    display: flex;
    color: var(--dark-gray);
    margin-top: 1%;
    font-size: 1.1em;
} */









#user-profile-order-card-detail-shipment {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 100%;
    
}

#user-profile-order-card-detail-shipment-info-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
    margin-top: 30px;
}

.user-profile-order-card-detail-shipment-info-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 60px;
    margin-bottom: 1%;
}

.user-profile-order-card-detail-shipment-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 60px;
    margin-bottom: 1%;
}

.user-profile-order-card-detail-shipment-info-label-txt {
    display: flex;
    user-select: none;
    color: var(--light-gray);
    font-size: 1.3em;
}

.user-profile-order-card-detail-shipment-info-txt {
    display: flex;
    color: var(--dark-gray);
    margin-top: 1%;
    font-size: 1.1em;
}

.user-profile-order-card-detail-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-top: 10px;
}

.user-profile-order-card-detail-title-txt {
    display: flex;
    font-size: 1.1em;
}

@media screen and (max-width: 700px) {

    #user-profile-order-card-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        min-width: 80px;
        flex-shrink: 0;
        margin-top: 1%;
        margin-bottom: 0.5%;
        border-radius: 10px;
        box-sizing: border-box;
        border: 1px solid #d8d5d5;    
    }

    #user-profile-order-card-resume {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
        height: 80px;
    }

    #user-profile-order-card-resume-number {
        display: flex;
        flex-direction: column;
        width: 36%;
        height: 100%;
        justify-content: center;
    }

    #user-profile-order-card-resume-status {
        display: flex;
        flex-direction: column;
        width: 30%;
        height: 100%;
        justify-content: center;
    }
    
    #user-profile-order-card-resume-date {
        display: none;
    }
    
    #user-profile-order-card-resume-value {
        display: flex;
        flex-direction: column;
        width: 24%;
        height: 100%;
        justify-content: center;
    }

    #user-profile-order-card-resume-shipment-code {
        display: none;
    }
    
    .user-profile-order-card-resume-label {
        display: flex;
        font-size: 1.2em;
        color: #d8d5d5;
        margin-left: 10%;
        user-select: none;
    }
    
    .user-profile-order-card-resume-info {
        display: flex;
        font-size: 1em;
        color: #000000;
        margin-top: 3%;
        margin-left: 10%;
    }

    #user-profile-order-card-resume-open-detail {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* width: 10%; */
        width: 3em;
        height: 100%;
        margin-left: 0%;
    }

    #user-profile-order-card-resume-open-detail-img {
        display: flex;
        width: 50%;
        cursor: pointer;
        user-select: none;
    }

    #user-profile-order-card-resume-open-detail-txt {
        display: none;
        width: auto;
        height: auto;
        color: var(--light-gray);
        font-size: 1em;
        margin-right: 15%;
        user-select: none;
        cursor: pointer;
    }




    #user-profile-order-card-detail {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
    }


    #user-profile-order-card-detail-products {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    #user-profile-order-card-detail-payment {
        display: flex;
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    #user-profile-order-card-detail-shipment {
        display: flex;
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 700px) {
    .user-profile-order-card-resume-label {
        display: flex;
        font-size: 1em;
        color: #d8d5d5;
        margin-left: 10%;
        user-select: none;
    }
    
    .user-profile-order-card-resume-info {
        display: flex;
        font-size: 0.8em;
        color: #000000;
        margin-top: 3%;
        margin-left: 10%;
    }
}