#product-card-main {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 600px;
    flex-shrink: 0;
    margin-right: 0.6%;
    margin-left: 0.6%;
    margin-bottom: 1%;
    border-radius: 20px;
    /* border: 1px solid #d8d5d5; */
    box-sizing: border-box;
    box-shadow: 3px 5px 7px #d8d5d5;
    cursor: pointer;

    transition: transform 0.3s ease-in-out;
}

#product-card-main:hover {
    transform: scale(1.03);
}

#product-card-main {
    transition: transform 0.3s ease-in-out;
}

#product-card-main:last-child {
    margin-bottom: 2%;
}

#product-card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    text-decoration: none;
    justify-content: center;
    align-items: center;
}

#product-card-image-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 65%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

#product-card-image {
    display: flex;
    border-radius: inherit;
    width: 100%;
    user-select: none;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    object-fit: cover;
    object-position: center;
}

#product-card-name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 13%;
}

#product-card-name {
    display: flex;
    width: auto;
    height: auto;
    text-align: center;
    font-size: 1.5em;
    color: var(--dark-gray);
}

#product-card-size-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 9%;
}

#product-card-variation2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-inline: 2%;
    width: 1.9em;
    height: 1.9em;
    border-radius: 50%;
    /* border: 1px solid var(--light-gray);  */
    cursor: pointer;
}


#product-card-price-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 13%;
}

#product-card-price-promotion {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#product-card-price-promotion-old-txt {
    display: flex;
    font-size: 1.2em;
    color: var(--light-gray);
    margin-top: 1%;
}

#product-card-price-promotion-new-txt {
    display: flex;
    font-size: 2.2em;
    font-weight: 500;
    color: var(--logo-green);
}

#product-card-price {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#product-card-price-txt {
    display: flex;
    font-size: 2em;
    margin-top: 2%;
    font-weight: 500;
    color: var(--logo-green);
}

@media screen and (max-width: 1250px) {
    #product-card-main {
        display: flex;
        flex-direction: column;
        width: 300px;
        height: 600px;
        flex-shrink: 0;
        margin-right: 0.5%;
        margin-left: 0.5%;
        margin-bottom: 3%;
        border-radius: 5px;
    
        border: 1px solid #d8d5d5;
        box-sizing: border-box;
        cursor: pointer;
    }
}
