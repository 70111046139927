#user-profile-address-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    overflow-x: hidden;
    background-color: #FFFFFF;
}

#user-profile-address-list-create-address {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 310px;
    height: 350px;
    flex-shrink: 0;
    margin-inline: 1%;
    margin-top: 1%;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #d8d5d5;  
    background-color: #d8d5d5;  
    cursor: pointer;
}

#user-profile-address-list-create-address-img {
    display: flex;
    width: 30%;
    user-select: none;
}

#user-profile-address-list-create-address-img:hover {
    display: flex;
    width: 30%;
    animation: grow 0.2s linear forwards;
}

#user-profile-address-list-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

#user-profile-address-list-empty-adversiment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 300px;
}

#user-profile-address-list-empty-adversiment-txt {
    display: flex;
    margin-top: 2%;
    text-align: center;
    font-size: 2.5em;
    color: var(--light-gray);
    user-select: none;
}

#user-profile-address-list-empty-new-address-btn {
    display: flex;
    margin-top: 4%;
    text-align: center;
    font-size: 2em;
    user-select: none;
    cursor: pointer;
    color: var(--light-gray);
}

#user-profile-address-list-empty-new-address-btn:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

@keyframes grow {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.2);
	}
}


@media screen and (max-width: 645px) {
    #user-profile-address-list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        flex-shrink: 0;
        background-color: #FFFFFF;
    }

    #user-profile-address-list-create-address {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 310px;
        height: 50px;
        flex-shrink: 0;
        margin-top: 2%;
        margin-bottom: 1%;
        border-radius: 10px;
        box-sizing: border-box;
        border: 1px solid #d8d5d5;  
        background-color: #d8d5d5;  
        cursor: pointer;
    }

    #user-profile-address-list-create-address-img {
        display: flex;
        width: 10%;
        user-select: none;
    }

    #user-profile-address-list-empty-adversiment {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 300px;
    }

    #user-profile-address-list-empty-adversiment-txt {
        display: flex;
        margin-top: 2%;
        text-align: center;
        font-size: 1.7em;
        color: var(--light-gray);
        user-select: none;
    }

    #user-profile-address-list-empty-new-address-btn {
        display: flex;
        margin-top: 20%;
        text-align: center;
        font-size: 1.2em;
        user-select: none;
        cursor: pointer;
        color: var(--light-gray);
    }
}