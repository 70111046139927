#product-category-template-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    z-index: 0;
    overflow-x: hidden;
    background: linear-gradient(180deg, #A9DEF9 30%, #FF99C8 100%);
}

#product-category-template-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    /* min-height: 100%; */
    height: 100%;
    flex-shrink: 0;
    background-color: #FFFFFF;
}

#product-category-template-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    background-color: rgb(255, 136, 0);
    border-bottom: 1px solid var(--light-gray);
}

#product-category-template-header-img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
}

#product-category-template-header-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    margin-top: 275px;
    left: 11.2%;
    width: auto;
    height: 80px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: var(--main-border-radius);
    background-color: #FFFFFF;
}

.product-category-template-header-title-spacer {
    display: flex;
    height: 1px;
    width: 70px;
}

#product-category-template-header-title-txt {
    display: flex;
    font-size: 3em;
    color: var(--light-gray);
}

#product-category-template-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    /* height: 715px;; */
    margin: 0;
    padding-top: 2%;
    flex-shrink: 0;
    min-height: 402px;
}


@media screen and (max-width: 1250px) {
    #product-category-template-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* min-height: 100%; */
        height: 100%;
        flex-shrink: 0;
        background-color: #FFFFFF;
    }
    
}

@media screen and (max-width: 650px){
    #product-category-template-header-title {
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0%;
    }

    .product-category-template-header-title-spacer {
        display: flex;
        height: 1px;
        width: 40px;
    }

    #product-category-template-header-title-txt {
        display: flex;
        font-size: 2em;
        color: var(--light-gray);
    }
}