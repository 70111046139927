#checkbox-without-text-main {
    display: flex;
}

#checkbox-without-text-selection {
    display: flex;
    justify-content: center;
    align-items: center;
}

#checkbox-without-text-selection-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    border-radius: var(--main-border-radius);
    border: 1px solid #d8d5d5;
    cursor: pointer;
}

#checkbox-without-text-selection-box-img {
    display: flex;
    width: 95%;
}