#header-user-menu-main {
    display: none;
    justify-content: center;
    align-items: center;
    height: 40%;
    width: 57px;
    margin-right: 5%;
    border-radius: var(--main-border-radius);
    border: var(--main-border);
    cursor: pointer;
    flex-shrink: 0;
}


#header-user-menu-menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    text-decoration: none;
}

#header-user-menu-menu-icon {
    display: flex;
    width: 60%;
}

@media screen and (max-width: 1121px) {
    #header-user-menu-main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40%;
        width: 57px;
        margin-right: 5%;
        border-radius: var(--main-border-radius);
        border: var(--main-border);
        cursor: pointer;
        flex-shrink: 0;
    }
}

@media screen and (max-width: 805px) {
    #header-user-menu-main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 9vw;
        width: 9vw;
        margin-right: 5%;
        border-radius: var(--main-border-radius);
        border: var(--main-border);
        cursor: pointer;
        flex-shrink: 0;
    }
}