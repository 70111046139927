#cancel-order-modal-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    height: 100%;
    background-color: #FFFFFF;
    border-left: var(--main-border);
}

#cancel-order-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 10%;
}

#cancel-order-title-txt1 {
    display: flex;
    font-size: 2em;
    margin-right: 1%;
    user-select: none;
}

#cancel-order-title-txt2 {
    display: flex;
    font-size: 2em;
    color: var(--dark-gray);
}

#cancel-order-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 50%;
}

#cancel-order-content-text-field {
    display: flex;
    width: 90%;
    height: 70%;
}

#cancel-order-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 20%;
}

#cancel-order-btn-confirm {
    display: flex;
    color: var(--dark-gray);
    font-size: 2em;
    margin-inline: 5%;
    user-select: none;
    cursor: pointer;
}

#cancel-order-btn-cancel {
    display: flex;
    color: var(--dark-gray);
    font-size: 2em;
    margin-inline: 5%;
    user-select: none;
    cursor: pointer;
}


#cancel-order-btn-confirm:hover {
    animation: var(--set-blue-color2);
}


#cancel-order-btn-cancel:hover {
    animation: var(--set-orange-color2);
}