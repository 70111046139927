#header-menu-entry-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    cursor: pointer;
    text-decoration: none;
    /* animation: asss 0.2s ease forwards; */
}

@keyframes asss {
    from {
        height: 45px;
    }
    to {
        height: 50px;
    }
}

#header-menu-entry-main:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}


.header-menu-entry-main-blue:hover {
    background-color: var(--main-blue);
}

.header-menu-entry-main-pink:hover {
    background-color: var(--main-pink);
}

.header-menu-entry-main-default:hover {
    background-color: var(--light-gray);
}

#header-menu-entry-txt {
    display: flex;
    font-size: 1.4em;
    color: var(--dark-gray);
    user-select: none;
}