#admin-settings-catalog-banners-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

#admin-settings-catalog-banners-btns-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 13%;
}

#admin-settings-catalog-banners-warning {
    display: flex;
    width: 50%;
    margin-right: 20%;
    font-size: 1.2em;
    color: var(--logo-orange);
}

#admin-settings-catalog-banners-new-btn {
    margin-right: 5%;
}

#admin-settings-catalog-banners-new-btn:hover {
    animation: var(--set-blue-color);
}

#admin-settings-catalog-banners-cancel-btn {
    margin-right: 2%;
}

#admin-settings-catalog-banners-cancel-btn:hover {
    animation: var(--set-orange-color);
}

#admin-settings-catalog-banners-save-btn {
    margin-right: 5%;
}

#admin-settings-catalog-banners-save-btn:hover {
    animation: var(--set-blue-color);
}


#admin-settings-catalog-banners-back-btn {
    margin-right: 2%;
}

#admin-settings-catalog-banners-back-btn:hover {
    animation: var(--set-orange-color);
}

#admin-settings-catalog-banners-delete-btn {
    margin-right: 5%;
}

#admin-settings-catalog-banners-delete-btn:hover {
    animation: var(--set-blue-color);
}

.admin-settings-catalog-banners-btns {
    display: flex;
    font-size: 2em;
    color: var(--light-gray);
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
}

.admin-settings-catalog-banners-btns:hover{
    text-decoration: underline;
    text-decoration-thickness: 1px;
}













/* #admin-settings-main-page-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

#admin-settings-main-page-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: #FFFFFF;
}

#admin-settings-main-page-labe-txt {
    display: flex;
    font-size: 1.3vw;
    color: var(--light-gray);
    user-select: none;
}

#admin-settings-main-page-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
}

#admin-settings-main-page-selector-container {
    display: flex;
    width: 30%;
    height: 60px;
    margin-right: 1%;
}

#admin-settings-main-page-add-banner-container {
    display: flex;
    width: 60px;
    height: 60px;
    margin-left: 1%;
}

#admin-settings-main-page-add-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: var(--main-border-radius);
    border: var(--main-border);
    box-sizing: border-box;
    cursor: pointer;
}

#admin-settings-main-page-add-banner-img {
    display: flex;
    width: 65%;
    user-select: none;
}

#admin-settings-main-page-delete-banner-container {
    display: flex;
    width: 60px;
    height: 60px;
    margin-left: 1%;
}

#admin-settings-main-page-delete-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: var(--main-border-radius);
    border: var(--main-border);
    box-sizing: border-box;
    cursor: pointer;
}

#admin-settings-main-page-delete-banner-img {
    display: flex;
    width: 50%;
    user-select: none;
}

#admin-settings-main-page-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
}


#admin-settings-main-page-image {
    display: flex;
    width: 70%;
    border-radius: var(--main-border-radius);
}

#admin-settings-main-page-image-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 10%;
}

#admin-settings-main-page-image-warning-txt {
    display: flex;
    text-align: center;
    font-size: 1.2vw;
    color: var(--logo-orange);
    animation: fade2 ease infinite alternate 2.4s;
}

#admin-settings-main-page-image-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
}

#admin-settings-main-page-image-upload-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 60%;
    background-color: var(--logo-green);
    border-radius: var(--main-border-radius);
    cursor: pointer;
}

#admin-settings-main-page-image-upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--logo-green);
    border-radius: var(--main-border-radius);
    cursor: pointer;
}

#admin-settings-main-page-image-upload-btn-txt {
    display: flex;
    font-size: 1.2vw;
    color: #FFFFFF;
    user-select: none;
    font-weight: 500;
}

#admin-settings-main-page-image-btn {
    display: flex;
    width: 100%;
    height: 20%;
} */
