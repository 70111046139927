#admin-settings-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 754px;
    border-top: var(--main-border);
    box-sizing: border-box;
    background-color: #FFFFFF;
}

#admin-settings-menu {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    box-sizing: border-box;
    border-right: var(--main-border);
}

#admin-settings-content {
    display: flex;
    width: 80%;
    height: 100%;
}

.admin-settings-menu-entry {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
}

.admin-settings-menu-entry-txt {
    display: flex;
    font-size: 1.3em;
    color: var(--dark-gray);
    cursor: pointer;
    user-select: none;
}

.admin-settings-menu-entry-txt:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

/* #admin-settings-special-categories {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100%;
    box-sizing: border-box;
    border-right: var(--main-border);
    border-left: var(--main-border);
}



#admin-settings-special-categories-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90%;
    overflow: scroll;
}

#admin-settings-special-categories-list-item-create {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 10%;
    flex-shrink: 0;
    margin-top: 5%;
    border: var(--main-border);
    border-radius: var(--main-border-radius);
}

#admin-settings-special-categories-list-item-create-txt {
    display: flex;
    font-size: 1.5vw;
    color: var(--lvw;
    color: var(--light-gray);
    user-select: none;
    cursor: pointer;
}


@keyframes fade2 {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
} */