#admin-product-list-product-main {
    display: flex;
    flex-direction: row;
    width: 95%;
    height: 10%;
    flex-shrink: 0;
    margin-top: 0.3%;
    border-radius: 5px;
    border: var(--main-border);
    box-sizing: border-box;
}

#admin-product-list-product-main:first-child {
    margin-top: 2%;
}

#admin-product-list-product-main:last-child {
    margin-bottom: 2%;
}


#admin-product-list-product-name {
    display: flex;
    align-items: center;
    height: 100%;
    width: 45%;
}

#admin-product-list-product-name-txt {
    display: flex;
    font-size: 1.2vw;
    margin-left: 2%;
    color: var(--dark-gray);
}

#admin-product-list-product-variations {
    display: flex;
    margin-left: 2%;
    width:43%;
    height: 100%;
}

#admin-product-list-product-actions {
  display: flex;
  justify-content: flex-end;
  width: 10%;
  height: 100%;
}

#admin-product-list-product-actions-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
}
#admin-product-list-product-actions-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
}

.admin-product-list-product-actions-img {
    display: flex;
    cursor: pointer;
    width: 55%;
    user-select: none;
}