#user-page-template-template-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    z-index: 0;
    background: linear-gradient(180deg, #A9DEF9 30%, #FF99C8 100%);
}

#user-page-template-template-content {
    display: flex;
    flex-direction: column;
    width: 65%;
    /* min-height: 100%; */
    height: 100%;
    flex-shrink: 0;
    background-color: #FFFFFF;
}

#user-page-template-template-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
    border-bottom: 1px solid #d8d5d5;
}

#user-page-template-template-header-img-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 8%;
    height: 100%;
}

#user-page-template-template-header-img {
    display: flex;
    margin-top: 10%;
    width: 100%;
    user-select: none;
}

#user-page-template-template-header-title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 100%;
}

#user-page-template-template-header-title-txt {
    display: flex;    
    font-size: 3.2em;
    user-select: none;
    color: #d8d5d5;
}

#user-page-template-template-header-navigation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42%;
    height: 100%;
}

#user-page-template-template-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* flex-wrap: wrap; */
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
}

@media screen and (max-width: 1250px) {
    #user-page-template-template-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* min-height: 100%; */
        height: 100%;
        flex-shrink: 0;
        background-color: #FFFFFF;
    }

    #user-page-template-template-header-title-txt {
        display: flex;    
        font-size: 2.3em;
        margin-left: 5%;
        user-select: none;
        color: #d8d5d5;
    }

    #user-page-template-template-header-navigation-container {
        display: none;
    }
}

@media screen and (max-width: 650px) {


    #user-page-template-template-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100px;
        border-bottom: 1px solid #d8d5d5;
    }
    
    #user-page-template-template-header-img-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 25%;
        height: 100%;
    }
    
    #user-page-template-template-header-img {
        display: flex;
        margin-top: 10%;
        width: 80%;
        user-select: none;
    }
    
    #user-page-template-template-header-title-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 75%;
        height: 100%;
    }
    
    #user-page-template-template-header-title-txt {
        display: flex;    
        font-size: 2.3em;
        margin-left: 5%;
        user-select: none;
        color: #d8d5d5;
    }
    

    #user-page-template-template-header-navigation-container {
        display: none;
        justify-content: center;
        align-items: center;
        width: 42%;
        height: 100%;
    }
    
    #user-page-template-template-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* min-height: 100%; */
        height: 100%;
        flex-shrink: 0;
        background-color: #FFFFFF;
    }

    #user-page-template-template-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* flex-wrap: wrap; */
        flex-direction: column;
        width: 100%;
        height: auto;
        min-height: 0px;
        margin: 0;
        padding: 0;
        flex-shrink: 0;
    }
}