#admin-ready-orders-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 754px;
    border-top: var(--main-border);
    box-sizing: border-box;
}

#admin-ready-orders-order-list-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 30%;
    height: 100%;
    /* background-color: yellowgreen; */
}

#admin-ready-orders-order-list-label {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    height: 11.5%;
    /* background-color: violet; */
    background-color: #FFFFFF;
}

.admin-ready-orders-order-list-label-text {
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 1.3vw;
    /* font-family: 'Montserrat', sans-serif; */
    color: #000000;
    /* background-color: yellowgreen; */
    margin-bottom: 2%;
}

#admin-ready-orders-order-list-label-number-text {
    margin-left: 10%;
}

#admin-ready-orders-order-list-label-date-text {
    margin-left: 34.5%;
}

#admin-ready-orders-order-list{
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
    height: 88.5%;
    /* background-color: #008000; */
    background-color: #FFFFFF;
}

#admin-ready-orders-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 100%;
    background-color: #FFFFFF;
    border-left: var(--main-border);
}

#admin-ready-orders-placeholder-txt {
    display: flex;
    font-size: 2vw;
    color: var(--light-gray);
}