#text-input-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
}

#text-input-text-label-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40%;
    /* background-color: aqua; */
}

#text-input-text-label {
    display: flex;
    font-size: 1.3em;
    margin-left: 3.5%;
    color: #7D7D7D;
}

#text-input-text-field-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60%;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #d8d5d5;
    background-color: white;
}

#text-input-currency {
    display: flex;
    font-size: 1.5em;
    color: var(--light-gray);
    margin-left: 2%;
    margin-bottom: 0.5%;
    user-select: none;
}

#text-input-text-field {
    width: 100%;
    height: 100%;
    padding: 0 3%;
    border: none;
    outline: none;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;

    font-size: 1.3em;
    caret-color: #7D7D7D;
    color: #7D7D7D;
    background-color: transparent;
}