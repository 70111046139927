#user-profile-address-update {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    height: 683px;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    overflow-x: hidden;
}

#user-profile-address-update-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: #FFFFFF;
}

#user-profile-address-update-label-txt {
    display: flex;
    color: var(--light-gray);
    font-size: 1.7vw;
    user-select: none;
}

#user-profile-address-update-fields {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90%;
}

.user-profile-address-update-fields-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 100%;
}

.user-profile-address-update-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 13.334%;
    margin-top: 8%;
}

.user-profile-address-update-sub-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
}

#user-profile-address-update-btns-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    width: 80%;
    height: 13.334%;
    margin-top: 8%;
    /* background-color: aqua; */
}

.user-profile-address-update-btns {
    display: flex;
    color: var(--light-gray);
    font-size: 1.9em;
    cursor: pointer;
    user-select: none;
}

.user-profile-address-update-btns:hover {
    animation: var(--set-orange-color);
}

.user-profile-address-update-btns-save {
    display: flex;
    color: var(--light-gray);
    font-size: 1.9em;
    cursor: pointer;
    user-select: none;
}

.user-profile-address-update-btns-save:hover {
    animation: var(--set-blue-color);
}

#user-profile-address-update-btns-cancel {
    display: flex;
    color: #7D7D7D;
    font-size: 1.2vw;
    cursor: pointer;
}

@media screen and (max-width: 500px) {

    #user-profile-address-update {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        height: 800px;
        margin: 0;
        padding: 0;
        flex-shrink: 0;
    }

    #user-profile-address-update-label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95%;
        height: 10%;
        background-color: #FFFFFF;
    }

    #user-profile-address-update-label-txt {
        display: flex;
        color: var(--light-gray);
        font-size: 1.5em;
        user-select: none;
        text-align: center;
    }

    #user-profile-address-update-fields {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90%;
    }

    .user-profile-address-update-fields-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 50%;
    }

    .user-profile-address-update-fields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 90%;
        height: 18%;
        margin-top: 6%;
    }
    

    #user-profile-address-update-btns-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 95%;
        height: 20%;
        margin-top: 2%;
    }

    #cart-address-create-address-btns-continue {
        display: flex;
        color: var(--light-gray);
        font-size: 1.9em;
        cursor: pointer;
        user-select: none;
    }

    .user-profile-address-update-btns {
        display: flex;
        color: var(--light-gray);
        font-size: 1.9em;
        cursor: pointer;
        user-select: none;
    }

    .user-profile-address-update-btns-save {
        display: flex;
        color: var(--light-gray);
        font-size: 1.9em;
        cursor: pointer;
        user-select: none;
    }
}