#user-profile-personal-data-content {
    display: flex;
    width: 100%;
    height: 683px;
    background-color: #FFFFFF;
}

#user-profile-personal-data-content-info1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 100%;
    /* background-color: rgb(139, 139, 100); */
}

#user-profile-personal-data-content-info2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    /* background-color: rgb(255, 136, 0); */
}

#user-profile-personal-data-content-textfield-container-first {
    margin-top: 8%;
}

.user-profile-personal-data-content-textfield-container {
    display: flex;
    margin-top: 3%;
    width: 90%;
    height: 12%;
}

@media screen and (max-width: 650px) {
    #user-profile-personal-data-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    #user-profile-personal-data-content-info1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    #user-profile-personal-data-content-textfield-container-first {
        margin-top: 10%;
    }

    #user-profile-personal-data-content-textfield-container-last {
        margin-bottom: 20%;
    }

    .user-profile-personal-data-content-textfield-container {
        display: flex;
        width: 95%;
        height: 65px;
        margin-bottom: 5%;
    }
    
    #user-profile-personal-data-content-info2 {
        display: none;
        flex-direction: column;
        width: 50%;
        height: 100%;
        background-color: rgb(255, 136, 0);
    }
}