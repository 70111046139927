#cart-payment-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 613px;
    /* background-color: rgb(111, 126, 56); */
}

#card-payment-method-selector-label {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 25%;
}

#card-payment-method-selector-label-txt {
    display: flex;
    text-align: center;
    font-size: 2em;
    color: var(--light-gray);
    user-select: none;
    margin-bottom: 1%;
}

#card-payment-method-selector-label-decoration {
    display: flex;
    width: 40vw;
    height: 1px;
    margin-top: 0.8%;
    margin-bottom: 0.5%;
    background-color: var(--light-gray);
}

#cart-payment-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75%;
}

#cart-payment-mp-btn {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

#cart-payment-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 650px) {

    #card-payment-method-selector-label-txt {
        display: flex;
        text-align: center;
        font-size: 1em;
        color: var(--dark-gray);
        user-select: none;
        margin-bottom: 1%;
    }
}