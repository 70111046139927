#recover-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 672px;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

    animation: fadeIn 0.2s linear;
}

#recover-logo-container {
    display: flex;
    height: 25%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#recover-logo-link {
    display: flex;
    height: fit-content;
    width: 25%;
    justify-content: center;
    align-items: center;
}

#recover-logo {
    display: flex;
    width:  100%;
}

#recover-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 45%;
}

.recover-input-container {
    display: flex;
    width: 75%;
    height: 80px;
}

.recover-input-container:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin-top: 10%;
    margin-bottom: 15%;
}

#recover-text {
    display: flex;
    text-align: center;
    user-select: none;
    font-size: 1.5em;
    color: var(--light-gray);
}

#recover-warning-message {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 61px;
    margin-top: 0;
    margin-bottom: 4%;
}

#recover-warning-message-txt {
    display: flex;
    user-select: none;
    font-size: 1.2em;
    color: var(--logo-orange);
    animation: fade 0.5s
}

#recover-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 13%;
}

#recover-btns-recover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 46px;
    border-radius: var(--main-border-radius);
    margin-bottom: 2%;
    cursor: pointer;
    background-color: var(--logo-green);
}

#recover-btns-recover-txt {
    display: flex;
    font-size: 1.2em;
    font-weight: 500;
    color: #ffffff;
    user-select: none;
}

#recover-btns-cancel {
    display: flex;
    font-size: 1.3em;
    cursor: pointer;
    color: #7D7D7D;
    user-select: none;
}

#recover-btns-cancel:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media screen and (max-width: 650px) {

    #recover-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 40%;
    }

    .recover-input-container {
        display: flex;
        width: 95%;
        height: 65px;
        margin-bottom: 25px;
    }
    
    .recover-input-container:first-child {
        display: flex;
        width: 95%;
        height: 65px;
        /* margin-bottom: 25px; */
    }

    .recover-input-container:last-child {
        display: flex;
        width: 95%;
        height: 65px;
        margin-bottom: 0;
    }

    #recover-warning-message {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
    }

    #recover-btns-recover {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 46px;
        margin-top: 5%;
        cursor: pointer;
        border-radius: var(--main-border-radius);
        background-color: var(--logo-green);
    }
    
    #recover-btns-login {
        display: none;
        font-size: 1.3em;
        cursor: pointer;
        color: #7D7D7D;
        user-select: none;
    }
}