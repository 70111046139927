#product-details-variation-main {
    display: flex;
    flex-shrink: 0;
    margin-right: 2%;
    width: 2em;
    height: 2em;
    border-radius: 10px;
    cursor: pointer;
    background-color: cadetblue;
}

#product-details-variation-notification {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    margin-left: 22px;
    margin-top: -7px;
    width: 1.4em;
    height: 1.4em;
}

#product-details-variation-notification-img {
    display: flex;
    width: 100%;
    user-select: none;
}


.product-details-variation-main-selected {
    border: 2px solid var(--dark-gray); 
}

.product-details-variation-main-unselected {
    border: 2px solid transparent; 
}