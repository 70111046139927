#cart-result-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 613px;
}

#cart-result-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
}

#cart-result-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
}

#card-result-info1-txt {
    display: flex;
    text-align: center;
    font-size: 2.3em;
    color: var(--light-gray);
    user-select: none;
}

#card-result-info2-txt {
    display: flex;
    font-size: 1.2em;
    color: var(--dark-gray);
    margin-top: 1%;
    user-select: none;
}

#cart-result-btn {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 20%;
}

#cart-result-profile-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 46px;
    cursor: pointer;
    margin-top: 2%;
    border-radius: var(--main-border-radius);
    background-color: var(--logo-green);
}

#cart-result-profile-btn-txt {
    display: flex;
    font-size: 1.2em;
    font-weight: 500;
    user-select: none;
    color: #FFFFFF;
}


@media screen and (max-width: 650px) {

    #card-result-info1-txt {
        display: flex;
        text-align: center;
        font-size: 1.5em;
        color: var(--light-gray);
        user-select: none;
    }
    
    #card-result-info2-txt {
        display: flex;
        text-align: center;
        font-size: 1em;
        color: var(--dark-gray);
        margin-top: 5%;
        user-select: none;
    }

    #cart-result-profile-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 46px;
        margin-top: 5%;
        cursor: pointer;
        border-radius: var(--main-border-radius);
        background-color: var(--logo-green);
    }

}